
import { AchatsService } from './../../demo/service/achats.service';
import { Component, OnInit,ViewChild } from '@angular/core';
import { AddAchatArticleComponent } from '../add-achat-article/add-achat-article.component';
import { MessageService } from 'primeng/api';
import { ConfigurationService } from 'src/app/demo/service/configuration.service';
import { ArticleService } from 'src/app/demo/service/article.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
interface Achat {
  id : number;
  // codeVariation: string;
  article
  ref: string;
  designation: string;
  qte: number;
  prixttc: number;
  prixht : number;
  remise: number;
  montant 
  montantht : number;
  variationsAchats,
  expanded:boolean
  TVA? : number,
}
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-achats',
  templateUrl: './achats.component.html',
  styleUrls: ['./achats.component.scss']
})
export class AchatsComponent implements OnInit {
  @ViewChild(AddAchatArticleComponent) addAchatDialog: AddAchatArticleComponent; 
  @ViewChild(ConfirmationDialogComponent) ConfirmDialog: ConfirmationDialogComponent; 

  expandedRows: number[] = [];
  isExpanded:boolean[] = [];
  loading: boolean = false;

 Etablissements
 EtablissementsStores
 selectedEtablissement
 codeFactureFourniss
 Fournisseurs
 stocks
 date: Date;
 selectedFournisseur
 selectedStore
 DetailsAchatParent
 filteredFourniss
 filtredEtablissements
 filtredDepot
confirm
 errorFlag: boolean = false; 
  Achats:Achat[]=[]
  AchatProduct =[]
  AchatVariations=[]
  message
  totalQuantity: number = 0;

tva=19
fodec:number =0
timbre:number =0

showFodec:boolean=false
  remisePercentage: number = 0.0;
  total :number = 0 ;
  htAmount: number = this.remisePercentage == 0.0 ? this.total :  (this.total * this.remisePercentage)/100 ;
  remiseAmount: number = (this.total * this.remisePercentage)/100;
  totalFodec: number =(this.htAmount*this.fodec)/100
  // totalFodec: number = (this.ttcAmount*101)/100;
  taxesAmount: number = ((this.htAmount * this.tva)/100)+this.totalFodec ;
  // timbreAmount: number = 0.6;
  timbreAmount: number = 0;

  ttcAmount: number = this.htAmount + this.taxesAmount ;
  netToPayAmount: number = this.ttcAmount + this.timbreAmount;
  constructor(private achatService:AchatsService,private messageService: MessageService,private configuration :ConfigurationService,private articleService :ArticleService) { 
    this.expandedRows = [];
    this.date = new Date();


  }
  calculateTotalQuantity() {
    this.totalQuantity = this.Achats.reduce((total, achat) => total + achat.qte, 0);
    console.log("total qte", this.totalQuantity )
  }
  onInput(event: any) {
    // Update the timbreAmount on input
    this.timbreAmount = parseFloat(event.target.value);
  }

  onChange(event: any) {
    // Round the timbreAmount to the desired decimal places after the change event
    this.timbreAmount = parseFloat(this.timbreAmount.toFixed(3));
  }

  getfiltredValue(event){
    console.log('value of article', event.query);
     this.achatService.getfournisseurWithContain(event.query).then(res=>{
      console.log(res)
    this.filteredFourniss=res.data.map(item=>({id:item.id,name:item.attributes.name}))})
  }
  validateInput() {
    if (this.remisePercentage < 0) {
      this.remisePercentage = 0;
    } else if (this.remisePercentage > 100) {
      this.remisePercentage = 100;
    }
  }
 
  validateRemise(event){
    // console.log(event)
    const inputValue = event.target.value;
    console.log(inputValue)
  
    // Ensure the input is a non-negative number between 0 and 100
    const pattern = /^(100|[1-9]?\d|0)$/;
    if (pattern.test(inputValue)) {
      this.remisePercentage = parseInt(inputValue, 10);
    } 
    else {
      this.remisePercentage = 0;
    }
  }
  validateTimbre(event){
 
   /* const inputValue = event.target.value;
  const dotIndex = inputValue.indexOf('.');
    const commaIndex = inputValue.indexOf(',');

    if (dotIndex !== -1 || commaIndex !== -1) {
      const decimalSeparatorIndex = dotIndex !== -1 ? dotIndex : commaIndex;
    const decimalPart = inputValue.substring(decimalSeparatorIndex + 1);

    if (decimalPart.length >= 3) {
      event.preventDefault();
    }
      }*/
      
      event.preventDefault();

   
  }
  
  cols = [
    { field: 'ref', header: 'Référence' },
    { field: 'designation', header: 'Designation' },
    { field: 'qte', header: 'Quantité' },
    // { field: 'prix', header: 'Prix' },
    {field: 'prixht', header: 'Prix HT'},
    {field: 'prixttc', header: 'Prix TTC'},
    {field: 'remise', header: 'Remise'},
    {field: 'Total_HT', header: 'Total HT'},
    {field: 'Total_TTC', header: 'Total TTC'},
    {field: 'action', header: 'Action'},
    {field: 'TVA', header: 'TVA'},
  

  ];
  async getConfiguration(){
    await this.configuration.getConfig().then(data=>{
      console.log("config data",data)
      this.tva=data.data.attributes.tva
        console.log("tva",  this.tva)
        const fodec=data.data.attributes?.fodec
        console.log("fodec",fodec)
        if(fodec>0){
          this.showFodec=true
          this.fodec=fodec

        }
        const timbre=data.data.attributes?.timbre
      console.log("timbre",timbre)
      if(timbre){
        this.timbre=parseFloat(timbre.toFixed(3))


      }

    })
  }
 async ngOnInit() {
await this.getConfiguration()
this.getEtablissement()
this.getFournisseurs()
this.timbreAmount=this.timbre
this.netToPayAmount= this.ttcAmount + this.timbreAmount;
// this.articleService.gettva().then(res=>{
//   console.log("tva",res)
//   this.tva=res.data.attributes.tva
// })
// this.getStocks()
  }
 
  filterFourniss(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.Fournisseurs as any[]).length; i++) {
        let country = (this.Fournisseurs as any[])[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }

    this.filteredFourniss = filtered;
}
filterDepots(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.selectedEtablissement.stores as any[]).length; i++) {
      let country = (this.selectedEtablissement.stores as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filtredDepot = filtered;
}
filterEtablissements(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.Etablissements as any[]).length; i++) {
      let country = (this.Etablissements as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filtredEtablissements = filtered;
}
  async getEtablissement(){
   await this.achatService.getEtablissements().then(res=>{
      
      console.log(res)
    this.Etablissements=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
      store=>({id:store.id,name:store.attributes.name})
    )}))})
    console.log(this.Etablissements)
    this.selectedEtablissement=this.Etablissements[0]
 this.selectedStore=this.Etablissements[0].stores[0]


  }
  getSelectedEatb(){
    this.filtredDepot=[]
    this.selectedStore=""
    console.log(this.selectedEtablissement)
    this.filtredDepot=this.selectedEtablissement.stores 
    this.selectedStore=this.selectedEtablissement.stores[0]
    console.log(this.selectedStore)
 
  }

  getSelectedStore(){
    console.log(this.selectedStore)
  }
 async getFournisseurs(){
  await  this.achatService.getfournisseur().then(res=>{
      console.log(res)
    this.Fournisseurs=res.data.map(item=>({id:item.id,name:item.attributes.name}))})
    console.log("fournisseur", this.Fournisseurs)
    // this.selectedFournisseur=this.Fournisseurs[0]

  }

  changeSelectedFourniss(){
    console.log("selected fourniss",this.selectedFournisseur)
  }
  // async getStocks(){
  //   await  this.achatService.getStocks().then(res=>{
  //       console.log(res)
  //     this.stocks=res.data.map(item=>({id:item.id,qte:item.attributes.quantity,article:item.attributes.product.data?.attributes.code_article_generique,store:item.attributes.store.data?.attributes.name}))
  //     console.log( this.stocks)
  //   })

  //   }
 async openDialog(){
  if(!this.selectedFournisseur){
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez Fournisseur' });
  }
  else{
    this.DetailsAchatParent={etablissement:this.selectedEtablissement,store:this.selectedStore,fournisseur:this.selectedFournisseur}
    console.log( this.DetailsAchatParent)
   await this.addAchatDialog.showDialog(this.DetailsAchatParent)
  }
  
      }

 toggleRow(item, rowIndex) {
  console.log(item,rowIndex);
  console.log(this.expandedRows);
  // Close previously expanded rows
  this.expandedRows.forEach((index) => {
    if (index !== rowIndex) {
      this.Achats[index].expanded = false;
    }
  });

  item.expanded = !item.expanded;
  console.log(item.expanded, rowIndex);

  if (item.expanded) {
    this.expandedRows = [rowIndex];
    console.log(this.expandedRows);
  } else {
    const indexToRemove = this.expandedRows.indexOf(rowIndex);
    if (indexToRemove !== -1) {
      this.expandedRows.splice(indexToRemove, rowIndex);
      console.log(this.expandedRows);
    }
  }
}
deleteRow(rowIndex): void {

  const mont = this.Achats[rowIndex].montantht
  this.Achats.splice(rowIndex, 1);
  
   this.total =  this.total- mont;
   this.htAmount = this.remisePercentage == 0.0 ? this.total :  (this.total * (100-this.remisePercentage))/100 ;
   this.remiseAmount = (this.total * this.remisePercentage)/100;
   //  this.totalFodec = (this.ttcAmount*101)/100;
   this.totalFodec = (this.htAmount*this.fodec)/100
   this. taxesAmount = ((this.htAmount * this.tva)/100 )+this.totalFodec;
   this.timbreAmount = this.timbreAmount;
   this.ttcAmount = this.htAmount + this.taxesAmount ;
  
   this.netToPayAmount = this.ttcAmount + this.timbreAmount;


}
 

updateTVAValues(ri: number) {
  console.log(ri);
  console.log(this.Achats[ri]);

  const achat = this.Achats[ri];
  
  // Calculate the price including taxes (TTC)
  const prixTTC = achat.prixht * (1 + achat.TVA / 100);
  const prixtotalttc = prixTTC * achat.qte
  // Update the price including taxes (TTC) and the price excluding taxes (HT)
  this.Achats[ri] = { ...achat, prixttc: prixTTC, prixht: achat.prixht  , montant : prixtotalttc };

  this.ttcAmount =  this.Achats.reduce((acc,sum) => acc + sum.montant, 0);
  this. taxesAmount = this.ttcAmount - this.htAmount;
  this.netToPayAmount = this.ttcAmount + this.timbreAmount;
   
}

updateValues() {
  this.htAmount = this.remisePercentage == 0.0 ? this.total :  (this.total * (100-this.remisePercentage))/100 ;
  this.ttcAmount = this.Achats.reduce((acc,sum) => acc + sum.montant, 0);
  this.remiseAmount = (this.total * this.remisePercentage)/100;
      // this.totalFodec = (this.ttcAmount*101)/100;
      this.totalFodec = (this.htAmount*this.fodec)/100
  this. taxesAmount =  this.ttcAmount - this.htAmount;
  this.timbreAmount = this.timbreAmount;


  this.netToPayAmount = this.ttcAmount + this.timbreAmount;

}


 ReceiveAchat(data) {
  console.log('Achat from child',data)
    const foundAchat= this.Achats.find((item) => item.article.id === data.article.id);
    if(foundAchat){

      const foundObjectIndex = this.Achats.findIndex((item) => item.article.id === data.article.id);
      const remiseOfFoundAchat=this.Achats[foundObjectIndex].remise

      console.log('Matching Achat found:', this.Achats[foundObjectIndex]);
      const AchatTomodify= this.Achats[foundObjectIndex]
      const variationsTomodify=this.Achats[foundObjectIndex].variationsAchats
      const oldMontantHt=AchatTomodify.montantht
      console.log("old montant ht",oldMontantHt)

             //same achat same remise 

     if(data.remise==remiseOfFoundAchat){
      for(let variationdata of data.achatVariations){
        const foundVariation= variationsTomodify.find((item) => item.ID === variationdata.ID);
        console.log('Matching variation found:', foundVariation);

        if(foundVariation){
          const foundVariationIndex = variationsTomodify.findIndex((item) => item.ID === variationdata.ID);
          console.log('index of Matching variation found:', variationsTomodify[foundVariationIndex]);
          const quantite = parseInt(variationdata.QTE, 10);
          variationsTomodify[foundVariationIndex].QTE = parseInt(variationsTomodify[foundVariationIndex].QTE,10) +quantite;
          console.log('modifyed  achats variation :', variationsTomodify[foundVariationIndex]);
          console.log('modifyed Achat :', this.Achats[foundObjectIndex]);
          AchatTomodify.qte+=quantite
          AchatTomodify.montantht= Math.round(AchatTomodify.qte*AchatTomodify.prixht*100)/100
          AchatTomodify.montant=Math.round(AchatTomodify.qte*AchatTomodify.prixttc*100)/100
          this.total-=oldMontantHt
          this.total += AchatTomodify.montantht;
          console.log("new total",this.total)
          this.htAmount=this.total
          console.log(this.Achats)
          this.calculateTotalQuantity()


        }
        else{
          variationsTomodify.push(variationdata)
          console.log('add new variation to existing Achat:', this.Achats[foundObjectIndex]);
          const quantite = parseInt(variationdata.QTE, 10);
          AchatTomodify.qte+=quantite
          AchatTomodify.montantht= Math.round(AchatTomodify.qte*AchatTomodify.prixht*100)/100
          AchatTomodify.montant=Math.round(AchatTomodify.qte*AchatTomodify.prixttc*100)/100
          this.total-=oldMontantHt
          this.total += AchatTomodify.montantht;
          console.log("new total",this.total)
          this.htAmount=this.total

          console.log(this.Achats)
          this.calculateTotalQuantity()


        }
      }
     }
      //same achat diff remise 
      else{
        console.log("ERMISE EXISTING ACHAT",remiseOfFoundAchat,"remise",data.remise)

          console.log('ACHAT WITH REMISE DIFFERENT');
          console.log(data);
          let sommeQte = 0;
         
           for (let variat of data.achatVariations) {
            const quantite = parseInt(variat.QTE, 10);
            if (!isNaN(quantite)) {
              sommeQte += quantite;
            }
          }
          const achat: Achat = {
                id : this.Achats!.length ? this.Achats.length : 0 ,
                article:data.article,
                ref: data.ref,
                designation: data.designation,
                qte: sommeQte,
                prixttc: data.prix,
                remise: data.remise,
                montant:Math.round(sommeQte*data.prix*100)/100,
                variationsAchats:data.achatVariations,
                expanded:false,
                prixht : data.prixht ,
                montantht : Math.round(sommeQte*data.prixht*100)/ 100,
                TVA : this.tva ,
    
              };
            
            console.log(achat);
            this.Achats.push(achat);   
                   console.log(achat.montant);
    
            this.total += achat.montantht;
            console.log(this.total);
    
          console.log(this.Achats)
          this.calculateTotalQuantity()
  
      }

    }
    else{
      console.log('ACHAT NOT FOUND');
      console.log(data);
      let sommeQte = 0;
     
       for (let variat of data.achatVariations) {
        const quantite = parseInt(variat.QTE, 10);
        if (!isNaN(quantite)) {
          sommeQte += quantite;
        }
      }
      const achat: Achat = {
            id : this.Achats!.length ? this.Achats.length : 0 ,
            article:data.article,
            ref: data.ref,
            designation: data.designation,
            qte: sommeQte,
            prixttc: data.prix,
            remise: data.remise,
            montant:Math.round(sommeQte*data.prix*100)/100,
            variationsAchats:data.achatVariations,
            expanded:false,
            prixht : data.prixht ,
            montantht : Math.round(sommeQte*data.prixht*100)/ 100,
            TVA : this.tva ,
          };
        
        console.log(achat);
        this.Achats.push(achat);   
               console.log(achat.montant);

        this.total += achat.montantht;
        console.log(this.total);

      console.log(this.Achats)
      this.calculateTotalQuantity()
    }
    this.updateValues();

      }
      roundValue(value: number): number {
        return Math.round(value * 100) / 100;
      }

      async onKeyUp(event: KeyboardEvent,achat,rowIndex,achatVariation){
        console.log("changed value",(event.target as HTMLInputElement).value)
      //   console.log("achat",achat,rowIndex,achatVariation)
      //   const foundAchat=this.Achats[rowIndex]
      //   console.log("Modified achat",foundAchat)
      //   const oldMontantHT=foundAchat.montantht
      //   for(let variations of foundAchat.variationsAchats){
      //     foundAchat.qte=0
      //   foundAchat.qte+=variations.QTE
      //   foundAchat.montantht=0
      //   foundAchat.montantht=Math.round(foundAchat.prixht* foundAchat.qte * 100)/ 100,
      //   console.log("montant HT",foundAchat.montantht)

      //   foundAchat.montant=0
      //   foundAchat.montant=Math.round(foundAchat.prixttc* foundAchat.qte * 100)/ 100,
      //   console.log("montant TTC",foundAchat.montant)

      //   }
      //   this.total-=oldMontantHT
      //   this.total+=foundAchat.montantht
      //   this.htAmount=this.total
      // console.log("total",this.total)
       
        
      }
      onQteChange(achat,rowIndex,achatVariation){
       
        const foundAchat=this.Achats[rowIndex]
        console.log("Modified achat",foundAchat)
        const oldMontantHT=foundAchat.montantht
        let somme=0

        for(let variations of foundAchat.variationsAchats){
          somme=somme+parseInt(variations.QTE)
     
        }

        // foundAchat.qte=0
        foundAchat.qte=somme
        foundAchat.montantht=0
        foundAchat.montantht=Math.round(foundAchat.prixht* foundAchat.qte * 100)/ 100,
        console.log("montant HT",foundAchat.montantht)

        foundAchat.montant=0
        foundAchat.montant=Math.round(foundAchat.prixttc* foundAchat.qte * 100)/ 100,
        console.log("montant TTC",foundAchat.montant)

        this.total-=oldMontantHT
        this.total+=foundAchat.montantht
        this.htAmount=this.total
      console.log("total",this.total)
      this.calculateTotalQuantity()
      this.updateValues();

      }

      // inputNumberChange(event,achat,rowIndex,achatVariation){
      //   console.log("input value",event.value)
      //   console.log("achat",achat,rowIndex,achatVariation)
      //   const foundAchat=this.Achats[rowIndex]
      //   console.log("Modified achat",foundAchat)
      //   const oldMontantHT=foundAchat.montantht
      //   for(let variations of foundAchat.variationsAchats){
      //     foundAchat.qte=0
      //   foundAchat.qte+=variations.QTE
      //   foundAchat.montantht=0
      //   foundAchat.montantht=Math.round(foundAchat.prixht* foundAchat.qte * 100)/ 100,
      //   console.log("montant HT",foundAchat.montantht)

      //   foundAchat.montant=0
      //   foundAchat.montant=Math.round(foundAchat.prixttc* foundAchat.qte * 100)/ 100,
      //   console.log("montant TTC",foundAchat.montant)

      //   }
      //   this.total-=oldMontantHT
      //   this.total+=foundAchat.montantht
      //   this.htAmount=this.total
      // console.log("total",this.total)
       
        
      // }
 
      onNgModelChange(newValue: any, variationsAchats: any) {
        console.log('ngModelChange value:', newValue);
        // Handle the input value when ngModel changes
      }

  receiveConfirm(data){
    console.log("response of confirmation",data)
    this.confirm=data
    if(this.confirm==1){
      this.saveAchat()
    }
    else{

          }
  }
 async AddAchat(){
    switch (true) {
      
      case !this.selectedEtablissement:
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez Etablissement' });
        break;
        case !this.selectedStore:
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez le dépôt' });
        break;
        case !this.selectedFournisseur:
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez Fournisseur' });
        break;
        case !this.date:
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sélectionnez date' });
          break;
          // case !this.codeFactureFourniss:
          //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Taper le Code Facture Fournisseur' });
          //   break;
        case this.Achats.length===0:
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Saisie une Achat' });
          break;
          default :
          await this.ConfirmDialog.showDialog()
        }
  }
 async  saveAchat(){
     this.ConfirmDialog.hideDialog()
  
    try {
      this.loading = true
      console.log(this.date)
      const currentDate = this.date; 
      currentDate.setDate(currentDate.getDate() + 1);

      const formattedDate = currentDate.toISOString();
      console.log(formattedDate);
      for(const achat of this.Achats){
        if (!this.AchatProduct.includes(achat.article.id)) {
  
        this.AchatProduct.push(achat.article.id)
        }
        for(const variationachat of achat.variationsAchats){
        //   if(!this.AchatVariations.includes(variationachat.ID)){
        //     this.AchatVariations.push(variationachat.ID)
        //  }
        const variation={
          "variation": 
          variationachat.ID,
              "quantity": variationachat.QTE,
              "remise": achat.remise,
              "prix_produit": achat.prixttc,
              "ht":achat.prixht,
              "tva":  achat.TVA

        }
        this.AchatVariations.push(variation)

        }
      }
      console.log(this.AchatVariations)

        console.log(this.AchatProduct)
     const data={
        "products": this.AchatProduct,
        "etablissement":this.selectedEtablissement.id,
        "store": this.selectedStore.id,
        "fournisseur": this.selectedFournisseur.id,
        "pourcentage_remise": this.remisePercentage,
        "remise": this.remiseAmount,
        "ht": this.htAmount,
        "taxes": this.taxesAmount,
        "ttc": this.ttcAmount,
        "total_fodec": this.totalFodec,
        "timbre": this.timbreAmount,
        "prix_net": this.netToPayAmount,
        "code_facture_fournisseur": this.codeFactureFourniss,
        "date_achat": formattedDate,
        "achat_variations":this.AchatVariations
      }
      console.log(data)
     await this.achatService.CreateAchat(data).then(res=>
     {   console.log(res)
      this.messageService.add({ severity: 'success', summary: '', detail: 'Achat créer avec succée' });

      this.selectedEtablissement=''
      this.selectedStore=''
      this.selectedFournisseur=''
      this.Achats=[]
      this.AchatVariations=[]
      this.total=0
      this.htAmount =0 ;
      this.remiseAmount =0;
      this. taxesAmount = 0 ;
      this.timbreAmount =this.timbre;
      this.ttcAmount =  0;
      this.totalFodec =0 ;
      this.netToPayAmount= this.ttcAmount + this.timbreAmount;
      this.remisePercentage=0
      this.totalQuantity=0}
      )
      .catch((err) => {
        console.error('Error in creating achat:', err);

      })
    
    } catch (error) {
      console.error('Error en creation achat:', error);

    } finally {
      this.loading = false; 
    } 
 
}
 
 
}
