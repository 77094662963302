import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<any>('https://prefacture.rosace.com.tn/api/users/me')
    .subscribe(
      (response) => {
        // Handle the successful response here
        console.log('API Response:', response);
      },
      (error) => {
        // Handle errors here
        console.error('API Error:', error);
      }
    );
  }

}
