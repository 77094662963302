import { Component, EventEmitter, OnInit, Output,Input,OnChanges, SimpleChanges,ViewChild  } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ArticleService } from 'src/app/demo/service/article.service';
import { Paginator } from 'primeng/paginator';
import { ConfigurationService } from 'src/app/demo/service/configuration.service';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss']
})
export class ListProductsComponent implements OnChanges  {
  // export class ListProductsComponent implements OnInit  {

  @Output()  ListProductToParent= new EventEmitter<any>();
  @Input() receivedData:any;
  @Input() receive:any;
  // @Input() dimension:any;
  @ViewChild('paginator', { static: true }) paginator: Paginator;



  first=0
  loading: boolean = true;
  products
  rowsPerPageOptions = [5, 10,20,25];
  paginationInfo: any;
  currentPage: number=1;
  pageSize: number=10;
  totalItems: number;
  pageNumbers: number[];
  currentpage
  selectedArticle
  sortOrder
  sortField
  expanded
  dimension: boolean
  private isFirstChange = true;

  constructor(private s:ArticleService ,private configurationService:ConfigurationService) { 
    this.configurationService.getConfig().then(res=>{
      console.log("config",res)
      this.dimension=res.data.attributes.article_dimensionne
    })
    console.log("dimonsion",this.dimension)

  }

  async ngOnChanges(changes: SimpleChanges){
    if (this.isFirstChange) {
      // await  this.onPageChange({ page: 0 , rows: this.pageSize });

      this.isFirstChange = false;
    } else {
      await  this.getFiltredDataFromChild()
   if(this.receive){
    console.log(this.receive)
  await  this.getProduct()
   }
console.log("dimonsion",this.dimension)
    }
  
  }
//   async ngOnInit() {
//       await  this.onPageChange({ page: 0 , rows: this.pageSize });
//   //  await  this.getFiltredDataFromChild()
//    if(this.receive){
//     console.log(this.receive)
//     await  this.getProduct()
//   }
// }
  cols = [
   
    { field: 'code_article_generique', header: "Code article" },
    { field: 'code_fournisseur', header: 'Code fournisseur ' },
    {field: 'image', header: 'Image'},
    { field: 'designation', header: 'Designation' },
    { field: 'sous_famille', header: 'Sous Famille' },
    {field: 'collection', header: 'Collection'},
    {field: 'rayon', header: 'Rayon'},
    {field: 'prix_achat_ttc', header: 'Prix Achat'},
    {field: 'prix_vente_ttc', header: 'Prix Vente'},
    {field: 'fournisseur', header: 'Fournisseur'},
    // {field: 'Action', header: 'Action'}
  ];

 async getFiltredDataFromChild(){

    console.log("Deatils Filter",this.receivedData)
if(this.receivedData){
      if (this.receivedData?.articles?.length > 0  || this.receivedData?.familles?.length > 0  || this.receivedData?.collections?.length > 0 
    || this.receivedData?.fourniss?.length > 0 || this.receivedData?.rayons?.length > 0 ) {
   const FournissIds = this.receivedData?.fourniss?.map(fourniss => fourniss.id);
   const articleIds = this.receivedData?.articles?.map(article => article.id);
   const FamillesIds = this.receivedData?.familles?.map(famille => famille.id);
   const RayonsIds = this.receivedData?.rayons?.map(rayons => rayons.id);
   const CollectionsIds = this.receivedData?.collections?.map(collection => collection.id);

   try {
    this.loading = true;
   
  await  this.s.getArticles(articleIds,FamillesIds,RayonsIds,CollectionsIds,FournissIds).then(res=>
    {
      console.log(res)
      this.products=[]
      this.products=res.data.map(items => ({
       
        id: items.id,
        code_article_generique:items.attributes?.code_article_generique,
        code_fournisseur:items.attributes?.code_fournisseur,
        image: {
        id: items.attributes?.image?.data?.id,
        name: items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
          ? "https://prefacture.rosace.com.tn" + items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
          : ""
          },
         designation:items.attributes?.designation,
         sous_famille:items.attributes?.sous_famille?.data?.attributes?.name,
         collection:items.attributes?.collection?.data?.attributes?.name,
         rayon:items.attributes?.rayon?.data?.attributes?.name,
         prix_achat_ttc:items.attributes?.prix_achat_ttc,
         prix_vente_ttc:items.attributes?.prix_vente_ttc,
         fournisseur:items.attributes?.fournisseur.data?.attributes?.name,
         nature:'',
         Unite_de_stock:'',
         variations:items.attributes?.variations.data.map(item=>({
          id:item.id,
          color:{id:item.attributes.color.data.id,name:item.attributes.color.data.attributes.name},
          taille:{id:item.attributes.taille.data.id,name:item.attributes.taille.data.attributes.name}
        })),
         selected:false,
         expanded:false

     }));
     this.paginationInfo = res.meta.pagination;
     this.currentPage = this.paginationInfo.page;
     this.pageSize = this.paginationInfo.pageSize;
     this.totalItems = this.paginationInfo.total;
     this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
    
    })
  } catch (error) {
    console.error('Error fetching products:', error);
  } finally {
    this.loading = false;
  }

 }
 else{
  this.sortField = null;
  this.sortOrder = null;
 await this.getProduct()
 }
 
}
// else{
//   this.getProduct()
//  }
    
  }
  onLazyLoad(event: LazyLoadEvent): void {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
      this.s.getSort({ sortMode: this.sortOrder, sortFiled: this.sortField });
      if(this.receivedData && (this.receivedData?.articles?.length > 0  || this.receivedData?.familles?.length > 0  || this.receivedData?.collections?.length > 0 
        || this.receivedData?.fourniss?.length > 0 || this.receivedData?.rayons?.length > 0)){
      this.getFiltredDataFromChild();
      }
      else{
         this.getProduct()
      }
    
    }

  async getProduct() {
    try {
      this.loading = true;
  
      const data = await this.s.getArticles();
      console.log("data", data);
  
      this.products = data.data.map(items => ({
       
         id: items.id,
          code_article_generique:items.attributes?.code_article_generique,
          code_fournisseur:items.attributes?.code_fournisseur,
          image: {
            id: items.attributes?.image?.data?.id,
            name: items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
              ? "https://prefacture.rosace.com.tn" + items.attributes?.image.data?.attributes?.formats?.thumbnail?.url
              : ""
          },
          designation:items.attributes?.designation,
          sous_famille:items.attributes?.sous_famille?.data?.attributes?.name,
          collection:items.attributes?.collection?.data?.attributes?.name,
          rayon:items.attributes?.rayon?.data?.attributes?.name,
          prix_achat_ttc:items.attributes?.prix_achat_ttc,
          prix_vente_ttc:items.attributes?.prix_vente_ttc,
          fournisseur:items.attributes?.fournisseur.data?.attributes?.name,
          nature:'',
          Unite_de_stock:'',
          variations:items.attributes?.variations.data.map(item=>({
            id:item.id,
            color:{id:item.attributes.color.data?.id,name:item.attributes.color.data?.attributes?.name},
            taille:{id:item.attributes.taille.data?.id,name:item.attributes.taille.data?.attributes?.name}
          })),
          selected:false,
          expanded:false
      }));
  
       
      this.ListProductToParent.emit(this.products)
      this.paginationInfo = data.meta.pagination;
      this.currentPage = this.paginationInfo.page;
      this.pageSize = this.paginationInfo.pageSize;
      this.totalItems = this.paginationInfo.total;
      this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
  
  
      console.log(this.products);
      console.log(this.paginationInfo);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      this.loading = false;
    }
  }
  checkboxChanged(event, art) {

  if (event.checked) {
    this.products.forEach(product => {
      if (product !== art) {
        product.selected = false;
      }
    });
    this.selectedArticle=art;
    // this.ListProductToParent.emit( this.selectedArticle)
    this.s.send(this.selectedArticle)
    console.log("selected article",this.selectedArticle);

  } else {
     this.selectedArticle='';
       console.log("selected article",this.selectedArticle);

  }  
  }
  toggleExpanded(rowData): void {
    rowData.expanded = !rowData.expanded;
  }
refreshPagination(){
  console.log('current page',this.paginator.getPageCount() )
  console.log('first',this.first)

  if(1 < this.paginator.getPageCount()  ){
    
     this.s.sendData({currentPage:1,pageSize:this.pageSize})
     this.paginator.first = 0;
  }
}
  async onPageChange(event) {
  
     console.log(event)
      this.currentPage=event.page
      this.pageSize=event.rows
      console.log(this.currentPage)
     //  await this.s.sendData(this.currentPage+1)
     await this.s.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
      //   if(event){
      //  await this.getProduct()
      //   }
    //  if(!this.receivedData){
    //    await this.getProduct()
    //  }
     if ((this.receivedData?.articles?.length > 0  || this.receivedData?.familles?.length > 0  || this.receivedData?.collections?.length > 0 
       || this.receivedData?.fourniss?.length > 0 || this.receivedData?.rayons?.length > 0)  && !this.receive) {
         await this.getFiltredDataFromChild()
       }
       else{
        await this.getProduct()
       }
      //   if(this.receive){
      //           await this.getProduct()

      //  }
         
    //  }

}
  }