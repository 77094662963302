import { throwError } from "rxjs";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { environment } from "./../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class AchatsService {
  currentPage=0
  pageSize=10
  code
  sortMode
  sortFiled
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) {
    
   }
   //**************************************handle errrors***************************************** */
   private handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
    
  }
     //******************************************************************************* */

  getEtablissements() {
    let promise = new Promise<any>((resolve, reject) => {
     
      let url = `${environment.url}/etablissements?populate=* `;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // reject(err);
          this.handleApiError(err);
          throw err; 
        });
    });
    return promise;
  }
  getFournisseurs() {
    let promise = new Promise<any>((resolve, reject) => {
     
      let url = `${environment.url}/fournisseurs?populate=* `;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // reject(err);
          this.handleApiError(err);
          throw err; 
        });
    });
    return promise;
  }
  async getfournisseurWithContain(data?) {
    let promise = new Promise<any>((resolve, reject) => {
      // Convert data and attribute to lowercase for case-insensitive search
      // const searchTerm = data ? data.toLowerCase() : '';
      const searchTerm = data ? data : "";
      const attribute = "name";
      let url = `${environment.url}/fournisseurs?filters[${attribute}][$containsi]=${searchTerm}`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });

    return promise;
  }
  getStocks() {
    let promise = new Promise<any>((resolve, reject) => {
     
      let url = `${environment.url}/stocks?populate=product&populate=product.fournisseur&populate=store&populate=variation&populate=variation.color&populate=variation.taille `;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
  async getArticles(data?) {
    let promise = new Promise<any>((resolve, reject) => {
        const searchTerm = data ? data : "";

      const attribute = "code_article_generique";

      let url = `${environment.url}/products?filters[${attribute}][$containsi]=${searchTerm}&populate=sous_famille&populate=variations
      &populate=variations.color&populate=variations.taille
      &populate=collection&populate=rayon&populate=fournisseur`;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // reject(err);
          this.handleApiError(err);
          throw err; 
        });
    });
    return promise;
  }
  async FilterArticles(fourniss?) {
    let promise = new Promise<any>((resolve, reject) => {
     
      let url = `${environment.url}/products?populate=sous_famille&populate=variations
      &populate=variations.color&populate=variations.taille
      &populate=collection&populate=rayon&populate=fournisseur`;
      if (fourniss ) {
        url += `&filters[fournisseur][id][$eq]=${fourniss}`;
    }
    
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // reject(err);
          this.handleApiError(err);
          throw err; 
        });
    });
    return promise;
  }
 
  async getMasques() {
    let promise = new Promise<any>((resolve, reject) => {
     
      let url = `${environment.url}/masque-tailles?populate=tailles_masques`;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // reject(err);
          this.handleApiError(err);
          throw err; 
        });
    });
    return promise;
  }

CreateAchat(data){
  let promise = new Promise<any>((resolve, reject) => {
     
    let url = `${environment.url}/createAchat?`;
    this.http
      .post(url,data)
      .toPromise()

      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // reject(err);
        this.handleApiError(err);
        throw err; 
      });
  });
  return promise; 
}

//***********************************consulte achat ****************************************** */
async  sendData(data: any) {

  this.currentPage=data.currentPage
  this.pageSize=data.pageSize
  console.log( this.currentPage)
}
async  getSort(data: any) {
this.sortMode=data.sortMode
this.sortFiled=data.sortFiled
console.log( this.sortMode)

}

formatDateToYYYYMMDD(date: Date): string {
  date.setDate(date.getDate() + 1);
  return date.toISOString();
}

ConsulteAchats(etabliss?,depot?,fourniss?,article?,date?){
  console.log(date)
  let promise = new Promise<any>((resolve, reject) => {
    const params = new HttpParams()
    .set('pagination[pageSize]',this.pageSize)
    .set('pagination[page]', this.currentPage)
     
    let url = `${environment.url}/achats`;
    url += '?populate=products&populate=etablissement&populate=store&populate=fournisseur&populate=achat_variations&populate=achat_variations.variation&populate=achat_variations.variation.color&populate=achat_variations.variation.taille';
     let filed
    if (this.sortFiled) {
      // Map the provided field to the corresponding API attribute
      switch (this.sortFiled) {
        case 'date':
          filed = 'date_achat';
          break;
        case 'fournisseurName':
          filed = 'fournisseur.name';
          break;
        case 'etablissement':
          filed = 'etablissement.name';
          break;
        case 'depot':
          filed = 'store.name';
          break;
          case 'num':
            filed = 'code_facture';
            break;
        case 'prixnet':
          filed = 'prix_net';
          break;
          case 'qte':
            filed = 'qte';
            break;  
        default:
          break;
      }
    }
    
    if (this.sortMode === 1) {
      url += `&sort=${filed}:DESC`;
    } else if (this.sortMode === -1) {
      url += `&sort=${filed}:ASC`;
    }

   
    if (fourniss && fourniss.length > 0) {
    for(let Fourniss of fourniss){
      url += `&filters[fournisseur][id][$eq]=${Fourniss}`;
    }
  }
 
    if (depot && depot.length > 0) {
      for(let Depot of depot){

      url += `&filters[store][id][$eq]=${Depot}`;
      }
    }
    if (article && article.length > 0) {
      for(let Article of article){

      url += `&filters[products][id][$eq]=${Article}`;
      }
    }
    
    if (date) {
      console.log(date)
      if (Array.isArray(date)) {
        if (date.length === 2 && date[1] != null) {
          const startDate = this.formatDateToYYYYMMDD(date[0]);
          const endDate = this.formatDateToYYYYMMDD(date[1]);
    
          url += `&filters[date_achat][$gte]=${startDate}`
          url += `&filters[date_achat][$lte]=${endDate}`;
        } else if (date.length === 2 && date[1] == null) {
          const formattedDate = this.formatDateToYYYYMMDD(date[0]);
          url += `&filters[date_achat][$eq]=${formattedDate}`;
        } else {
          console.error('Invalid date range:', date);
        }
      } else {
        const formattedDate = this.formatDateToYYYYMMDD(date);
        url += `&filters[date_achat][$eq]=${formattedDate}`;
      }
    }
    // if (date) {
    //   const formattedDate = this.formatDateToYYYYMMDD(date);

    //   url += `&filters[date_achat][$eq]=${formattedDate}`;
    // }
  
    this.http
      .get(url,{params})
      .toPromise()

      .then((res: any) => {

        if (Array.isArray(res.data)) {
          // Sort the array based on 'qte' field if applicable
          if (this.sortFiled === 'qte') {
            res.data.sort((a, b) => {
              const qteA = a.qte || 0; // handle potential undefined qte values
              const qteB = b.qte || 0;
              return (this.sortMode === 1) ? qteA - qteB : qteB - qteA;
            });
          }
        }
        resolve(res);
      })
      .catch((err) => {
        // reject(err);
        this.handleApiError(err);
        throw err; 
      });
  });
  return promise; 
}























//**************************************************************************************** */
saveAchat(data){
  return this.http.post(`${environment.url}/createAchat`,data)
 
}
async getProducts() {
  return this.http.get<any>('https://prefacture.rosace.com.tn/api/products?filters[code_article_generique][$contains]='+this.code+'&populate=image&populate=filters[variations][$notNull]=true&populate=variations.color&populate=variations.taille&populate=fournisseur')
  .toPromise()
  
}
// async  sendData(data: any) {
//   // Do something with the data
//   console.log(data)
//   this.code=data.code
//   this.currentPage=data.page
// }
getAllAchats(){
  const params = new HttpParams()
  .append('populate', 'store')
  .append('populate', 'fournisseur')
  .append('populate', 'store.name')
  .append('populate', 'product')
  .append('populate', 'variation')
  .append('populate', 'product.image')
  .append('populate', 'achat_variations')
  .append('populate', 'achat_variations.variation')
  .append('populate', 'achat_variations.variation.taille')
  .append('populate', 'achat_variations.variation.color')
  .append('sort', 'id:DESC')
  .append('pagination[pageSize]', this.pageSize)
  .append('pagination[page]', this.currentPage);

      
return this.http.get(`${environment.url}/achats?`,{params})
// populate=store&populate=store.name&populate=product&populate=variation&
// populate=product.image&populate=achat_variations&populate=achat_variations.variation&
// populate=achat_variations.variation.taille&populate=achat_variations.variation.color`)
}
  async getAchats(filter) {
      console.log(filter)
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/achats?populate=store&populate=product&populate=variation&populate=product.image&populate=variation.color&populate=variation.taille&populate=achat_variations`;
      filter.forEach(data=>{
        url +="&filters"+data.param + "[$"+data.eperator +"]="+data.value
      })
      
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  uploadImage(data){
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/api/upload`;
     
      
      this.http
        .post(url,data)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  postFile(filesToUpload:  any[], catalogacion: any): Observable<any> {
    let url = 'https://prefacture.rosace.com.tn/api/upload';
   

    const formData: FormData = new FormData();

    formData.append('json', JSON.stringify(catalogacion));

    for (let file of filesToUpload) {
      formData.append('documento', file.data, file.data.name);
    }

    console.log(formData);

    

    return this.http.post(url, formData );
  }


  async getStore() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/stores`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }
  //  getStore() {
  //   return this.http.get(`${environment.url}/stores`)
  // }
  async getfournisseur() {
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/fournisseurs`;
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
}
