import { Component, OnInit , ChangeDetectorRef ,NgZone   } from '@angular/core';
import * as XLSX from 'xlsx';
import { ArticleService } from './../../demo/service/article.service';

interface Product {
  code_article_generique?: string;
  code_barre?: string;
  rayon?: string;
  sous_famille?: string;
  collection?: string;
  fournisseur?: number;
  prix_achat_ht?: number;
  prix_vente_ht?: number;
  tva?: number;
  code_fournisseur?: number;
  designation?: string;
}

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})


export class ExportComponent implements OnInit {

  products : Product[] = [] ;
  selectedProducts : string[] = [] ; 
  attributeList: string[];
  visible: boolean;
  data: any[];
  loading = false ;
  visibleExp = false ;
  keydata : any[] = [];
  selecteddata : string[];
  keys : string[] = [] ;
  keydatatemp: { attr: string; datakeys: string[]; };
  isLoading: boolean = false;
  selectedItem : string ; 
  filteredItems : string[] = ['ventes' , 'factures', 'products' , 'clients' , 'fournisseurs' , 'achats' , 'stocks']
  

  constructor(private articleService:ArticleService  , private cdr: ChangeDetectorRef , private ngZone: NgZone) { }

  ngOnInit(): void {
  }
  filterItems(event) {
    let query = event.query;
    this.filteredItems = this.filter(query, ['ventes', 'factures']);
  }
  
  filter(query, items: any[]):any[] {
    let filtered : any[] = [];
    for(let i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(item);
      }
    }
    return filtered;
  }
  visibleitem():boolean {
    return this.selectedItem != undefined  
  }

   async  getArticle(){
    this.selectedProducts = []
    this.loading = true ;
    this.data = await this.articleService.GetForexport(this.selectedItem);
    this.loading = false ;
    this.attributeList = Object.keys(this.data[0].attributes).filter(attr => {
      if (['createdAt', 'updatedAt', 'uuid', 'image'].includes(attr)) {
          return false;
      }
      if(typeof this.data[0]?.attributes[attr] === "object" && !this.data[0]?.attributes[attr]?.data?.attributes ){
        return false ;
      }
      return true;
  });
    this.visible = false;
  }

  onFileChange(event) {
    this.selectedProducts = []
    let reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsArrayBuffer(file);
  
      reader.onload = () => {
        let data = new Uint8Array(reader.result as ArrayBuffer);
        let workbook = XLSX.read(data, {type: 'array'});
        
        // Assuming that your data is in the first sheet
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let jsonData = XLSX.utils.sheet_to_json(worksheet);
        this.products = jsonData as Product[];
        console.log(jsonData);
        this.attributeList = Object.keys(jsonData[0]);
        console.log(this.attributeList);

      };
    }
    this.visible = false;
  }
  async senddata() : Promise<void> {
    this.isLoading = true; 
    document.body.classList.add('loading');

    await new Promise<void>((resolve, reject) => {
      let sortedData = [];
      let wb = XLSX.utils.book_new();
     if (this.data){
      for (let prod of this.data) {
        let sortedProd = {};
        for (let key of this.selectedProducts) {
          let value = prod.attributes[key];
        
                  if (typeof value === "object" && value !== null) {
                    console.log('key : ',key);
                    console.log('data :',this.keydata)
                   const index = this.keydata.findIndex(v => v.attr === key);
                   console.log('index : ',index)
                   this.keydata[index].datakeys.forEach(Ukey => {
                    let valeur = '';
                    if(Array.isArray(value.data)) {
                       value.data.forEach(data => {
                         valeur +=  data.attributes[Ukey] + ' , '
                       });
                    }else if (value.data){
                      valeur = value.data?.attributes[Ukey] ? value.data.attributes[Ukey] : null ;
                    }
                    sortedProd[key + '_' + Ukey] = JSON.stringify(valeur);
                   });

                  //  for (let Ukey of this.keydata[index].datakeys ) {
                  
                  //  }
                     
                       
                   } else {
                      sortedProd[key] = value;
                   }
                }
        sortedData.push(sortedProd);
      }
      
  
  let ws = XLSX.utils.json_to_sheet(sortedData);
  
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
  XLSX.writeFile(wb, "sortedData.xlsx");
     }else {
      for (let prod of this.products) {
        let sortedProd = {};
        for (let key of this.selectedProducts) {
          let value = prod[key];
                  sortedProd[key] = typeof value === 'object' ? JSON.stringify(value) : value;
        }
        sortedData.push(sortedProd);
      }}
      resolve();
    });

    this.isLoading = false;
    document.body.classList.remove('loading');
}



  onMoveToTarget(event){
    this.keydatatemp = {
      attr : null,
      datakeys : [],
    }
    console.log(event);
    this.keys = [];
    this.selecteddata = [];
    if (typeof this.data[0].attributes[event.items[0]] === 'object' && this.data[0].attributes[event.items[0]] ) {
     // this.selectedProducts = this.selectedProducts.filter(item => item !== event.items[0]);
      this.visibleExp = true ;
      if(this.data[0].attributes[event.items[0]].data.attributes){
        this.keys = Array.isArray(this.data[0].attributes[event.items[0]].data) ? Object.keys(this.data[0].attributes[event.items[0]].data[0]?.attributes).filter(attr => {
          if (['createdAt', 'updatedAt', 'uuid'].includes(attr)) {
              return false;
          }
          return true;
      }) : Object?.keys(this.data[0].attributes[event.items[0]].data?.attributes).filter(attr => {
        if (['createdAt', 'updatedAt', 'uuid'].includes(attr)) {
            return false;
        }
        if (typeof attr === 'object') {
          return false;
      }
      if ( attr === null || attr ===  undefined ) {
        return false;
    }
        return true;
    }) ;
      }
      
      this.keydatatemp = {
        attr : event.items[0] ,
        datakeys : [],
      }
      } 
  }

  visibility():boolean {
    return this.selecteddata.length > 0;
  }

  Checkdata(){
        const index = this.keydata.findIndex(v => v.attr === this.keydatatemp.attr);
        console.log('index is here :' , index) ;
        if(index != -1) {
          this.keydata[index].datakeys = this.selecteddata;
        } else {
          this.keydatatemp.datakeys = this.selecteddata;
          this.keydata.push(this.keydatatemp);
        }
        console.log(this.keydata);
        this.visibleExp = false;
      } 

  showDialog(){
    this.visible = true;
  }
}
