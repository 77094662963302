import { Component, OnInit , ViewChild  } from '@angular/core';
import { TicketZserviceService } from 'src/app/demo/service/TicketZservice.service';
import { ModReglement, TicketZ } from 'src/app/models/TicketZ';
import * as html2pdf from 'html2pdf.js';
import { VenteService } from 'src/app/demo/service/vente.service';
import { Table } from 'primeng/table';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-ticket-z',
  templateUrl: './ticket-z.component.html',
  styleUrls: ['./ticket-z.component.scss']
})



export class TicketZComponent implements OnInit {
     @ViewChild('dataTable') dataTable: Table;
    
  totalRecords 
  loading = false;
  TicketZ : TicketZ [] = [];
  imprimdialog: boolean = false ;
  Ticket: TicketZ ;
  Reglements : ModReglement [] = [];
  size : number = 10;
  page : number = 1 ;
  sort ='numero' ;
  option =  1 ;
  date
  filtredStore
  filtredEtablissement
  selectedEtablissement
  selectedStore
  Etablissements
  Depots
  add
  

  filterStore(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.Depots as any[])?.length; i++) {
        let country = (this.Depots as any[])[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }

    this.filtredStore = filtered;
  }
  filterEtablissement(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.Etablissements as any[]).length; i++) {
        let country = (this.Etablissements as any[])[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }

    this.filtredEtablissement = filtered;
  }
  async getEtablissement(){
    await this.venteService.getEtablissements().then(res=>{
      
      console.log(res)
    this.Etablissements=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
      store=>({id:store.id,name:store.attributes.name})
    )}))})
    console.log(this.Etablissements)


  }
  async getSelectedEatb(){
    console.log("selected Etablissement",this.selectedEtablissement)
    this.Depots=this.selectedEtablissement.stores
  }


  formatDate(dateString: string, type: string): string {
    const date = new Date(dateString);
    if (type === 'date') {
      return date.toLocaleDateString('en-US');
    } else if (type === 'datetime') {
      return date.toLocaleString('en-US');
    } else {
      return '';
    }
  }
  constructor(private TZS : TicketZserviceService ,private venteService:VenteService) { }

  async ngOnInit() {
    await this.getEtablissement()
  }

  async refresher(){
    this.date = undefined ;
    this.page = 1 ;
    this.sort = undefined ;
    this.selectedStore = undefined ;
    this.add = undefined ;
    await this.getTicket()
     
  }
  
async getTicket() {
    try {
        console.log(typeof this.date); // Corrected typo here
        console.log(this.date);
        
        // Check if this.date is an array
        // if (Array.isArray(this.date)) {
        //     // Check if the first element of the array is a Date object
        //     if (this.date[0] instanceof Date) {
        //         this.date[0].setDate(this.date[0].getDate());
        //     }
        //     // Check if the second element of the array is a Date object
        //     if (this.date[1] instanceof Date) {
        //         this.date[1].setDate(this.date[1].getDate());
        //     }
        // }
        
        this.TicketZ = await this.TZS.getTicektZ(this.page, this.size, this.sort,  this.option, this.date, 'date_fin', this.add);  
        this.totalRecords = this.TZS.total ;
         if (Array.isArray(this.date)) {
            // Check if the first element of the array is a Date object
            if (this.date[0] instanceof Date) {
                this.date[0].setDate(this.date[0].getDate()-1);
            }
            // Check if the second element of the array is a Date object
            if (this.date[1] instanceof Date) {
                this.date[1].setDate(this.date[1].getDate()-1);
            }
        }
    } catch (e) {
        throw e;
    }
}
  async filtrer(){
    this.loading = true ;
    this.dataTable.first = 0;
    console.log(this.selectedStore)
    this.add = this.selectedStore ? `&filters[$and][0][store][id][$eq]=${this.selectedStore.id}` : ``;
    await this.getTicket()
    this.loading = false ;
  }

  async onLazyLoad(event) {
    this.page = Math.floor(event.first / event.rows) + 1;
    this.size = event.rows;
    this.sort = event.sortField;
    this.option = event.sortOrder;
    this.loading = true;
    await this.getTicket();
    console.log(this.TicketZ);
    this.loading = false;
    console.log(event);
  }

    Cols: { header: string, field: string }[] = [
    { header: 'Numero', field: 'numero' },
    { header: 'Date', field: 'date_fin' },
    { header: 'Operation CH', field: 'operationCH' },
    { header: 'Operation Qty', field: 'operationCHQte' },
    { header: 'NbTicket', field: 'nbTicket' },
    { header: 'Store Name', field: 'store' },
  ];

  imprimticketZ(ticketZ : TicketZ){
   console.log(ticketZ);
   this.Ticket = ticketZ ;
   this.Reglements = ticketZ.attributes.modReglement;
   console.log('reg',this.Reglements);
   this.imprimdialog = true ; 
  }

  opendialog(){
    const content = document.getElementById('TicketContent');
    const options = {
      margin: 10,
      filename: 'Facture.pdf',
      image: { type: 'jpeg', quality: 0.90 }, 
      html2canvas: { scale: 4, useCORS: true }, // Set scale to 1 to maintain original size
      jsPDF: { unit: 'px', format: [content.offsetWidth, content.offsetHeight], orientation: 'portrait' }, // Set format to match content size
    };
    html2pdf().set(options).from(content).save(); 
    this.imprimdialog = false ;
  }


}


