import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundToThreeDecimals'
})
export class RoundToThreeDecimalsPipe implements PipeTransform {

transform(value: number, type?: boolean , remise? : boolean): string {
    if (type) {
        const [integerPart, decimalPart] = value.toFixed(3).split('.');
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return `${formattedIntegerPart},${decimalPart}`;
    }
    if (remise) {
      const [integerPart, decimalPart] = value.toFixed(2).split('.');
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
      return `${formattedIntegerPart},${decimalPart}`;
  }

    return value.toFixed(3);
}
}


