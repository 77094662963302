<div class="grid">
  <div class="col-12">
    <div class="card">

      <!--------------------Consultation Tickets PAGE---------------------->

      <h5>Consultation Ticket Z </h5>
      <div class="container">
        

        <!------------------------------------------  Filtres et buttons  -------------------------------------------------------->
        <div style="display: flex; justify-content: space-between; align-items: center;">

          <!-- Inputs on the left -->
          <div style="display: flex; align-items: center;">
            <!-- <p-multiSelect
                [options]=""
                placeholder="Societes"
                optionLabel="name"
                [filter]="true"
                [style]="{ 'margin-right': '5px', 'max-width': '250px', 'min-width': '150px' }"
            ></p-multiSelect> -->
            <p-autoComplete  [(ngModel)]="selectedEtablissement"  [suggestions]="filtredEtablissement"  (completeMethod)="filterEtablissement($event)"  placeholder="Etablissement" 
				field="name" [dropdown]="true"   (onSelect)="getSelectedEatb()" style = "margin-right : 10px;"
				></p-autoComplete>

          <p-autoComplete  [(ngModel)]="selectedStore"  [suggestions]="filtredStore"  (completeMethod)="filterStore($event)" placeholder="Depot"
          field="name" [dropdown]="true"   style = "margin-right : 10px;" 
          ></p-autoComplete>
            <p-calendar [(ngModel)]="date" selectionMode="range" placeholder="Date" [readonlyInput]="true">
            </p-calendar>
          </div>
        
          <!-- Buttons on the right -->
          <div>
            <button pButton pRipple type="button" icon="fa fa-filter bi bi-filter" label="Filtrer"
              class="p-button-success mr-0.5 mb-" style="margin-right: 5px; " (click)="filtrer()">
            </button>
        
            <button pButton pRipple type="button" icon="bi bi-arrow-clockwise" label="Refresher"
              class="btn btn-primary mr-0.5 mb-" (click)="refresher()"
              style="margin-right: 4px;  background-color: rgba(0, 78, 156, 0.158); color: black;">
            </button>
          </div>
        
        </div>

      </div>

      <!------------------------------------------------------------------------------------------------------------------------>
      <!-------------------------------------------------------------- Table --------------------------------------------------->

      <p-table #dataTable [paginator]="true" class="fadeinleft animation-duration-1000   border-round m-2 px-5 py-3"
        [value]="TicketZ" [columns]="Cols" [tableStyle]="{'min-width': '60rem'}" [rows]="10" sortMode="single"
        responsiveLayout="stack" scrollHeight="flex" dataKey="id" [lazy]="true" [totalRecords]="totalRecords"
        [loading]="loading" (onLazyLoad)="onLazyLoad($event)" [rowsPerPageOptions]="[5, 10]">


        <!-------------------- Buttons et recherche ---------------------->

      

        <!-------------------- Columns ---------------------->

        <ng-template pTemplate="header" let-columns>

          <tr>
            <th *ngFor="let col of columns" pSortableColumn="{{ col.field }}">
              {{ col.header }}
              <p-sortIcon field="{{ col.field }}"></p-sortIcon>
            </th>
            <th style="width: 15%">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="filter" placeholder="Rechercher" class="w-full"
                  style="height: 35px;" />
                <span class="p-input-icon-right mb-2" (click)="clearFilter()">
                  <i class="pi pi-times"></i>
                </span>
              </span>
            </th>
          </tr>
        </ng-template>

        <!------------------------------------------------>

        <ng-template pTemplate="body" let-ticket let-rowIndex="rowIndex">
          <tr>
            <td *ngFor="let col of Cols">

              <!-------------------- Display mode  ---------------------->

              <ng-container *ngIf="col.field !== 'store' && col.field !== 'operationCH' && col.field !== 'date_fin'; else StoreOrOperationField">
                {{ ticket.attributes[col.field] }}
              </ng-container>
              <ng-container *ngIf="col.field === 'date_fin'">
                {{ formatDate(ticket.attributes[col.field], 'date') }} 
              </ng-container>
              <ng-template #StoreOrOperationField>
                <ng-container *ngIf="col.field === 'store'">
                  {{ ticket.attributes.store.data.code }}
                </ng-container>
                <ng-container *ngIf="col.field === 'operationCH'">
                  {{ ticket.attributes[col.field] | roundToThreeDecimals }}
                </ng-container>
              </ng-template>
              <!-------------------- Edit mode  ---------------------->
            </td>
            <td style="text-align: center;">
              <button style="align-items: center;" type="button" pButton pRipple icon="bi bi-receipt"
                (click)="imprimticketZ(ticket)"></button>
            </td>
          </tr>
        </ng-template>
        <!-------------------- footer Paginator and gererate button  ---------------------->
      </p-table>

      <p-dialog header="Header" [(visible)]="imprimdialog" [style]="{width: '400px' , height :'50vw'}">
        <ng-template pTemplate="header">
          <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">Ticket 1</span>
          </div>
        </ng-template>
        <div id="TicketContent" >
        <title>Ticket Z</title>
        <style>
          table {
            width: 100%;
            /* border-collapse: collapse; */
          }

          th,
          td {
            /* border: 1px solid #ddd; */
            padding: 0px;
            text-align: left;
          }

          th {
            /* background-color: #f2f2f2; */
          }
        </style>

<h4 style="text-align: center;">Ticket Z Cloture n° {{ Ticket?.attributes?.numero }} du {{ formatDate(Ticket?.attributes?.date_fin, 'date') }} </h4>
<p style="text-align: center;">Etab Caisse Date d'impression: {{ Ticket?.attributes?.store?.data?.code }} {{ formatDate(Ticket?.attributes?.date_fin, 'datetime') }}</p>


        <h5 style="text-align: center;" >==== OPERATIONS DE LA JOURNEE ====</h5>
        <table *ngIf="Ticket?.attributes?.numero">
          <thead>
            <tr>
              <th>TYPE</th>
              <th style=" text-align: right;">VENTES</th>
              <th style=" text-align: right;">RETOURS</th>
              <th style=" text-align: right;">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SUR STOCK</td>
              <td style=" text-align: right;">{{Ticket?.attributes.operationCH | roundToThreeDecimals }}</td>
              <td style=" text-align: right;">0,00</td>
              <td style=" text-align: right;">{{Ticket?.attributes.operationCH | roundToThreeDecimals }}</td>
            </tr>
            <tr>
              <td>PRESTATION</td>
              <td style=" text-align: right;">0,00</td>
              <td style=" text-align: right;">100,00</td>
              <td style=" text-align: right;">-100,00</td>
            </tr>
            <tr>
              <td>FINANCIER</td>
              <td style=" text-align: right;">0,00</td>
              <td style=" text-align: right;">0,00</td>
              <td style=" text-align: right;">0,00</td>
            </tr>
            <tr>
              <td style="font-weight: bold;" >TOTAL</td>
              <td style=" text-align: right; font-weight: bold;">{{Ticket?.attributes.operationCH | roundToThreeDecimals }}</td>
              <td style=" text-align: right; font-weight: bold;">0,00</td>
              <td style=" text-align: right; font-weight: bold;">{{Ticket?.attributes.operationCH | roundToThreeDecimals }}</td>
            </tr>
          </tbody>
        </table>

        <h5 style="text-align: center;" >========== QUANTITÉS ==========</h5>
        <table>
          <thead>
            <tr>
              <th>TYPE</th>
              <th style="text-align: center;">VENTES</th>
              <th style="text-align: center;" >RETOURS</th>
              <th style="text-align: center;">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SUR STOCK</td>
              <td style="text-align: center;">{{Ticket?.attributes?.operationCHQte}}</td>
              <td style="text-align: center;">0</td>
              <td style="text-align: center;">{{Ticket?.attributes?.operationCHQte}}</td>
            </tr>
            <tr>
              <td>PRESTATION</td>
              <td style="text-align: center;">0</td>
              <td style="text-align: center;">0</td>
              <td style="text-align: center;">0</td>
            </tr>
            <tr>
              <td>FINANCIER</td>
              <td style="text-align: center;">0</td>
              <td style="text-align: center;">0</td>
              <td style="text-align: center;">0</td>
            </tr>
            <tr> 
              <td style="font-weight: bold;" >TOTAL</td>
              <td style="text-align: center;font-weight: bold;">{{Ticket?.attributes?.operationCHQte}}</td>
              <td style="text-align: center;font-weight: bold;">0</td>
              <td style="text-align: center;font-weight: bold;">{{Ticket?.attributes?.operationCHQte}}</td>
            </tr>
          </tbody>
        </table>

        <h5 style="text-align: center;"  *ngIf="Ticket"> ==== REGLEMENTS DE LA JOURNEE ====</h5>
        <table>
          <thead>
            <tr >
              <th >MODE PAIEMENT</th>
              <th style=" text-align: center;">NB</th>
              <th style=" text-align: right;">TOTAL</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of Reglements">
            <tr *ngIf="item.qty != 0">
              <td>{{item.name}}</td>
              <td style=" text-align: center;">{{item.qty}}</td>
              <td style=" text-align: right;">{{item.ch | roundToThreeDecimals}}</td>
            </tr>
            <tr *ngIf="item.name === 'espece'">
              <td>{{item.name}}</td>
              <td style=" text-align: center;">{{item.qty + 1 | roundToThreeDecimals}} </td>
              <td style=" text-align: right;">{{item.ch + 100 | roundToThreeDecimals}}</td>
            </tr>
            <tr *ngIf="item.name === 'espece'">
              <td></td>
              <td style=" text-align: center;">-1</td>
              <td style=" text-align: right;">-100,00</td>
            </tr>
            
          </tbody>
          <tfoot>
            <!-- <tr>
              <td>Total encaissement :</td>
              <td colspan="2" style=" text-align: right;">{{Ticket?.attributes?.operationCH | roundToThreeDecimals }}</td>
            </tr>
            <tr>
              <td>Total autres règlements :</td>
              <td colspan="2" style=" text-align: right;">0</td>
            </tr> -->
            <tr>
              <td style="font-weight: bold;">Total caisse :</td>
              <td colspan="2" style=" text-align: right;font-weight: bold;">{{Ticket?.attributes?.operationCH | roundToThreeDecimals }}</td>
            </tr>
          </tfoot>
        </table>
        <h5 style="text-align: center;"  *ngIf="Ticket">=============================</h5>
        <h5 style="text-align: center;"  *ngIf="Ticket">INFORMATIONS COMPLEMENTAIRES</h5>
        <table>
          <thead>
            <tr >
      
              <th style=" text-align: center;"></th>
              <th style=" text-align: center;"></th>
            
            </tr>
          </thead>
          <tbody  *ngIf="Ticket">
            <tr>
              <td>Nombre de tickets</td>
              <td style=" text-align: right;">{{Ticket?.attributes?.nbTicket}}</td>
            </tr>
            <tr>
              <td>Nombre d'articles</td>
              <td style=" text-align: right;">{{Ticket?.attributes?.operationCHQte}}</td>
            </tr> <tr>
              <td>Nombre d'art / ticket'</td>
              <td style=" text-align: right;">{{Ticket?.attributes?.nbTicket / Ticket?.attributes?.operationCHQte || 0 | roundToThreeDecimals}}</td>
            </tr> <tr>
              <td>Remises consesties</td>
              <td style=" text-align: right;"> 0 DT</td>
            </tr> <tr>
              <td>C.A. TTC</td>
              <td style=" text-align: right;">{{Ticket?.attributes?.operationCH  | roundToThreeDecimals }}</td>
            </tr> <tr>
              <td>C.A. /Ticket</td>
              <td style=" text-align: right;">{{Ticket?.attributes?.operationCH / Ticket?.attributes?.nbTicket  || 0 | roundToThreeDecimals }}</td>
            </tr> <tr>
              <td>C.A. Article</td>
              <td style=" text-align: right;">{{Ticket?.attributes?.operationCH / Ticket?.attributes?.operationCHQte || 0  | roundToThreeDecimals }}</td>
            </tr>
            
          </tbody>
          
        </table>
      </div>
        <ng-template pTemplate="footer">
          <p-button icon="pi pi-check" (click)="opendialog()" label="Imprimer" [autofocus]="true"></p-button>
          <p-button icon="pi pi-check" (click)="imprimdialog = false" label="Ok" pAutoFocus [autofocus]="true"></p-button>
        </ng-template>
      </p-dialog>
      <div class="card flex justify-content-center"></div>
      <!------------------------------------------------------------------------------------------------------------------------>
    </div>
    
  </div>