import { pairs } from 'rxjs';
import { Component, OnInit,ViewChild } from '@angular/core';
import { ArticleService } from 'src/app/demo/service/article.service';
import { ConfigurationService } from 'src/app/demo/service/configuration.service';
import { ListProductsComponent } from '../list-products/list-products.component';

@Component({
  selector: 'app-consulte-article',
  templateUrl: './consulte-article.component.html',
  styleUrls: ['./consulte-article.component.scss']
})
export class ConsulteArticleComponent implements OnInit {
  @ViewChild(ListProductsComponent) listProductComponent: ListProductsComponent;

  selectedFournisseur
  selectedCollection
  selectedRayon
  selectedFamille
  selectedArticle
  Articles
  Familles
  Rayons
  Collections
  Fournisseurs
  DetailsFilter
  refresh=""
  // dimensionner
  constructor(private articleService:ArticleService,private configurationService:ConfigurationService) { }

  ngOnInit(): void {
    this.getArticles()
    this.getFournisseurs()
    this.getCollections()
    this.getRayons()
    this.getFamilles() 
    // this.configurationService.getConfig().then(res=>{
    //   console.log("config",res)
    //   this.dimensionner=res.data.attributes.article_dimensionne
    // })

  }

  async getArticles(){
    try {
      await this.articleService.getArticlesWithContain().then(res=>
        {console.log(res)
        this.Articles=res?.data.map(items=>
          ({
            id: items.id,
            code_article_generique:items?.attributes?.code_article_generique,
           
          })
          )
        
        })
        console.log(this.Articles)
      } catch (error) {
        console.error('Error:', error);
      } 
     
  }

  async getFournisseurs(){
    try {

    await this.articleService.getFournisseurs().then(res=>
      {console.log(res)
      this.Fournisseurs=res?.data.map(items=>
        ({
          id: items.id,
          name:items?.attributes?.name,
         
        })
        )
      
      })
      console.log(this.Fournisseurs)
    } catch (error) {
      console.error('Error:', error);
    } 
  }

  async getFamilles(){
    try {

    await this.articleService.getSousFamille().then(res=>
      {console.log(res)
      this.Familles=res?.data.map(items=>
        ({
          id: items.id,
          name:items?.attributes?.name,
         
        })
        )
      
      })
      console.log(this.Familles)
    } catch (error) {
      console.error('Error:', error);
    } 
  }

  async getCollections(){
    try {

    await this.articleService.getCollection().then(res=>
      {console.log(res)
      this.Collections=res?.data.map(items=>
        ({
          id: items.id,
          name:items?.attributes?.name,
         
        })
        )
      
      })
      console.log(this.Collections)
    } catch (error) {
      console.error('Error:', error);
    } 
     
  }

  async getRayons(){
    try {

    await this.articleService.getRayons().then(res=>
      {console.log(res)
      this.Rayons=res?.data.map(items=>
        ({
          id: items.id,
          name:items?.attributes?.name,
         
        })
        )
      
      })
      console.log(this.Rayons)
    } catch (error) {
      console.error('Error:', error);
    } 
  }
  
  async getfournissfiltredValue(event){
     try {

    await this.articleService.getfournissWithContain(event.filter).then(res=>
      {console.log(res)
      this.Fournisseurs=res?.data.map(items=>
        ({
          id: items.id,
          name:items?.attributes?.name,
         
        })
        )
      
      })
      console.log(this.Fournisseurs)
    } catch (error) {
      console.error('Error:', error);
    } 
  }

 async getfiltredValue(event){
    console.log('value of article', event.filter);
    await this.articleService.getArticlesWithContain(event.filter).then(res=>
      {console.log(res)
      this.Articles=res?.data.map(items=>
        ({
          id: items.id,
          code_article_generique:items?.attributes?.code_article_generique,
         
        })
        )
      
      })
  }
   async getfiltredFamilleValue(event){
   try {

    await this.articleService.getfamillesWithContain(event.filter).then(res=>
      {console.log(res)
      this.Familles=res?.data.map(items=>
        ({
          id: items.id,
          name:items?.attributes?.name,
        })
        )
      
      })
      console.log(this.Familles)
    } catch (error) {
      console.error('Error:', error);
    } 
  }
  
  onArticleChange(event: any) {
    // console.log(event)
    console.log('Selected article:', this.selectedArticle);
  }
  onFamilleChange(event: any) {
    console.log('Selected famille:', this.selectedFamille);
  }
  onRayonChange(event: any) {
    console.log('Selected rayon:', this.selectedRayon);
  }
  onCollectionChange(event: any) {
    console.log('Selected collection:', this.selectedCollection);
  }
  onFournisseurChange(event: any) {
    console.log('Selected fourniss:', this.selectedFournisseur);
  }

async getFiltredArticles(){
  this.refresh=""
  this.DetailsFilter={articles:this.selectedArticle,familles:this.selectedFamille,rayons:this.selectedRayon,collections:this.selectedCollection,fourniss:this.selectedFournisseur}
    console.log("DetailsFilter", this.DetailsFilter)
    this.listProductComponent.refreshPagination()
}
 refreshfilter(){
  this.selectedArticle=[]
  this.selectedCollection=[]
  this.selectedFamille=[]
  this.selectedRayon=[]
  this.selectedFournisseur=[]
  this.DetailsFilter={}
//   this.sortField = null;
//   this.sortOrder = null;
  console.log("check detail filter",this.DetailsFilter)
  // await new Promise(resolve => setTimeout(resolve, 1000));
  // await this.refreshfilter()
  this.refresh=('refresh')
  this.listProductComponent.refreshPagination()

}

}
