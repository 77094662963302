<div class="col-12">
    <div class="card">
        <h5>Consultations Articles</h5>
           
        <div class="container">
        <p-multiSelect [(ngModel)]="selectedArticle" [options]="Articles"
        (onChange)="onArticleChange($event)" placeholder="Articles" (onFilter)="getfiltredValue($event)"
        optionLabel="code_article_generique" [filter]="true" [style]="{ 'margin-right': '10px', 'max-width' : '200px','width':'150px' }">
        </p-multiSelect>

        <p-multiSelect [(ngModel)]="selectedFamille" [options]="Familles" (onFilter)="getfiltredFamilleValue($event)"
        (onChange)="onFamilleChange($event)" placeholder="Sous_Familles"
        optionLabel="name" [filter]="true" [style]="{ 'margin-right': '10px', 'max-width' : '200px','width':'150px' }">
        </p-multiSelect>

        <p-multiSelect [(ngModel)]="selectedRayon" [options]="Rayons"
        (onChange)="onRayonChange($event)" placeholder="Rayons"
        optionLabel="name" [filter]="true" [style]="{ 'margin-right': '10px', 'max-width' : '200px','width':'150px' }">
        </p-multiSelect>

        <p-multiSelect [(ngModel)]="selectedCollection" [options]="Collections"
        (onChange)="onCollectionChange($event)" placeholder="Collections"
        optionLabel="name" [filter]="true" [style]="{ 'margin-right': '10px', 'max-width' : '200px','width':'150px' }">
        </p-multiSelect>

        <p-multiSelect [(ngModel)]="selectedFournisseur" [options]="Fournisseurs" (onFilter)="getfournissfiltredValue($event)"
        (onChange)="onFournisseurChange($event)" placeholder="Fournisseur"
        optionLabel="name" [filter]="true" [style]="{ 'margin-right': '10px', 'max-width' : '200px','width':'150px' }">
        </p-multiSelect>

        <button pButton pRipple type="button" 
        icon="fa fa-filter bi bi-filter" 
        label="Filtrer" 
        class="p-button-success mr-0.5 mb-"
        style="margin-right: 4px;"
        (click)="getFiltredArticles()">
        </button>

        <button pButton pRipple type="button" 
        icon="bi bi-arrow-clockwise" 
        label="Refresher" 
        class="btn btn-primary mr-0.5 mb-" 
        style="margin-right: 4px; background-color: rgba(0, 78, 156, 0.158); color: black;"
        (click)="refreshfilter()"></button>
        </div>
           

<!-- <app-list-products [receivedData]="DetailsFilter" [receive]="refresh" [dimension]="dimensionner" ></app-list-products> -->
<app-list-products [receivedData]="DetailsFilter" [receive]="refresh" ></app-list-products>

</div>
</div>