<div class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Facture Achats</h5>
			<br>
			<p-toast></p-toast>
			<div class="container">
			<div class="content">
				
<!-- 
				<p-dropdown  class="drop"   #etablissement="ngModel" [(ngModel)]="selectedEtablissement" [options]="Etablissements"  required optionLabel="name" (onChange)="getSelectedEatb()" placeholder="Etablissement"
				[ngClass]="{ 'invalid-input': errorFlag && (etablissement.invalid || !selectedEtablissement )}"></p-dropdown>
				<p-dropdown  class="drop"  #store="ngModel"   [(ngModel)]="selectedStore"  [options]="EtablissementsStores"  required optionLabel="name" placeholder="Depot"
				[ngClass]="{ 'invalid-input': errorFlag && (store.invalid || !selectedEtablissement) }"></p-dropdown>
				<p-dropdown  class="drop" #fournisseur="ngModel" [(ngModel)]="selectedFournisseur"  [options]="Fournisseurs" required  optionLabel="name" placeholder="Fournisseur"
				[ngClass]="{ 'invalid-input': errorFlag && (fournisseur.invalid || !selectedEtablissement) }"></p-dropdown> -->
			     
				<p-autoComplete  [(ngModel)]="selectedEtablissement"  [suggestions]="filtredEtablissements"  (completeMethod)="filterEtablissements($event)"  placeholder="Etablissement" 
				field="name" [dropdown]="true" style = "margin-right : 10px;" (onSelect)="getSelectedEatb()"
				></p-autoComplete>

				<p-autoComplete  [(ngModel)]="selectedStore"  [suggestions]="filtredDepot"  (completeMethod)="filterDepots($event)" placeholder="Depot"
				field="name" [dropdown]="true"   style = "margin-right : 10px;" (onSelect)="getSelectedStore()"
				></p-autoComplete>
				<p-autoComplete [(ngModel)]="selectedFournisseur" (completeMethod)="getfiltredValue($event)" [suggestions]="filteredFourniss" placeholder="Fournisseur"
				field="name" [dropdown]="true" style = "margin-right : 10px;" (onSelect)="changeSelectedFourniss()">
</p-autoComplete>





				<div  class="custom-cal" style = "margin-right : 10px;" >
				<p-calendar class="drop"  [(ngModel)]="date" [showWeek]="true"[showIcon]="true" inputId="icon"></p-calendar>
			    </div>
				<!-- <div class="field" style = "width: fit-content" >
				<label htmlFor="name">Code Facture Fournisseur</label>
				<input pInputText id="name" type="text"   [(ngModel)]="codeFactureFourniss" />
			</div> -->
				<button  pButton pRipple type="button" icon="pi pi-plus" label="Ajouter achat articles " class="p-button-success mr-2 mb-" (click)=" openDialog()"></button>
				<div class="messages-container">
				<!-- <p-messages ></p-messages> -->
				</div>

			   




			</div>
		</div>
		<br>

            <p-table [columns]="cols" [value]="Achats" dataKey="id" responsiveLayout="stack" scrollHeight="flex"  >
			
                <!-- <ng-template pTemplate="caption">
                    <button  pButton pRipple type="button" icon="pi pi-plus" label="Ajouter achat articles " class="p-button-success mr-2 mb-2" (click)=" openDialog()"></button>
                   

                    <div style="position: relative; float: right; ">
                    <span class="p-input-icon-left mb-2">
                       <i class="pi pi-search"></i>
                       <input pInputText type="text" #filter  placeholder="Rechercher" class="w-full"  style="height: 35px; "/>
       
                   </span>
                </div>
               </ng-template> -->			
                <ng-template pTemplate="header" let-columns>
					
					<tr>
						<th style="width:10rem; text-align: center; position: relative;">
							<button type="button" pButton pRipple [pRowToggler]="{}" class="p-button-text p-button-rounded p-button-plain" 
									icon="pi pi-chevron-right" (click)="toggleAllRows()"></button>
						  </th>
						  <th *ngFor="let col of columns" style="width:10rem;border-left: 1px solid #ccc; padding-left: 10px;">

							<!-- <div  *ngIf="col.field=='codeVariation'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div> -->

						<div  *ngIf="col.field=='ref'"class="flex justify-content-between align-items-center">
							
							{{ col.header }}

						</div>


						<div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
							{{ col.header }}

						</div>
						
						<div  *ngIf="col.field=='qte'"class="flex justify-content-between align-items-center">
							{{ col.header }}


						</div>

							 
						<div  *ngIf="col.field=='remise'"class="flex justify-content-between align-items-center">
							
							{{ col.header }}


						</div>

						<div  *ngIf="col.field=='Total_HT'"class="flex justify-content-between align-items-center">
							{{ col.header }}


						</div>
						  
						


                        <div *ngIf="col.field=='prixht'" class="flex justify-content-between align-items-center">
							{{ col.header }}
							
<!-- 						
							<div class="header-container">
							<span style="margin-bottom: 8px;">{{ col.header }}</span>

						<p-table class="sub-table">
							<ng-template pTemplate="header" >

						<tr>
							<th> Prix HT</th>
							<th>  Prix TTC</th>
						</tr>
						</ng-template>
					   </p-table>
                        </div> -->
					</div>

					<div  *ngIf="col.field=='prixttc'"class="flex justify-content-between align-items-center">
						{{ col.header }}
					</div>
					
                        <div  *ngIf="col.field=='Total_TTC'"class="flex justify-content-between align-items-center">
							{{ col.header }}
						</div>

						<div  *ngIf="col.field=='TVA' "class="flex justify-content-between align-items-center">
							{{ col.header }}
						</div>

                      
                        <div  *ngIf="col.field=='action'"class="flex justify-content-between align-items-center">
							{{ col.header }}
						</div>


						
                     
						</th>
					</tr>
				
			
					</ng-template>
				   <!-- </p-table>
				</tr> 
				</ng-template>-->



                <ng-template pTemplate="body" let-rowData   let-columns="columns" let-Achats let-ri="rowIndex" dataKey="rowIndex">
					<tr  [pEditableRow]="columns">

						<td style="width:10rem;border-right: 1px solid #ccc; text-align: center;">
							<button type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" 
							[icon]="rowData.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"   style="font-size: 1em;"></button>

                        </td>
						
						<td style="width:10rem;min-width: 14rem;border-right: 1px solid #ccc;text-align: center;" *ngFor="let col of columns">
					  
							
							<div  *ngIf="col.field=='ref'"class="flex justify-content-between align-items-center">
								 {{Achats.ref}}
							</div>
						
						   <div  *ngIf="col.field=='designation'"class="flex justify-content-between align-items-center">
								
							{{Achats.designation }}
					   </div>
					   <div  *ngIf="col.field=='qte'"class="flex justify-content-between align-items-center">
								
						{{Achats.qte}}
				   </div>
				      
			   <div  *ngIf="col.field=='remise'"class="flex justify-content-between align-items-center">
								
				{{Achats.remise}}
			</div>
				   <div  *ngIf="col.field=='prixht'"class="flex justify-content-between align-items-center">
								
                    {{Achats.prixht | roundToThreeDecimals }}
               </div>
			   <div  *ngIf="col.field=='prixttc'"class="flex justify-content-between align-items-center">
								
				{{Achats.prixttc | roundToThreeDecimals }}
		   </div>
                   <div  *ngIf="col.field=='prix'"class="flex justify-content-between align-items-center">
								
                    {{Achats.prix}}
               </div>
				
			<div  *ngIf="col.field=='Total_HT'"class="flex justify-content-between align-items-center">
					
				{{Achats.montantht | roundToThreeDecimals}}
		   </div>
							
			<div  *ngIf="col.field=='Total_TTC'"class="flex justify-content-between align-items-center">
					
				{{Achats.montant | roundToThreeDecimals }}
		   </div>
		   <div  *ngIf="col.field=='TVA'"class="flex justify-content-between align-items-center">
					
			<p-inputNumber  [showButtons]="true" [min]="0" [max]="100" [(ngModel)]="Achats.TVA" prefix="%" (ngModelChange)="updateTVAValues(ri)" > </p-inputNumber>
	   </div>
		
		   <div  *ngIf="col.field=='action'"class="flex justify-content-between align-items-center">
						<button pButton pRipple type="button" icon="pi pi-trash" 
			class="p-button-danger mr-2 mb-2" style="width: 40px; background-color: #EF4444;"
			(click)="deleteRow(ri)">
			</button>
	
			
	   </div>

		   <!-- <div  *ngIf="col.field=='action'"class="flex justify-content-between align-items-center">
			</div
			 -->
						</td>
                    </tr>
					</ng-template>
					<ng-template pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex" > 
						<tr style="text-align: center; background-color: rgb(159, 195, 216);">
						  <td colspan="5" style="text-align: center; background-color: rgb(159, 195, 216);" >
							<div class="p-p-6">
							  <p-table [value]="rowData.variationsAchats" dataKey="id" style="margin-right: 40px;background-color: rgb(159, 195, 216);">
								<ng-template pTemplate="header">
								  <tr style="text-align: center; background-color: rgb(159, 195, 216);" >
									<th style="text-align: center; background-color: rgb(159, 195, 216);"
									>Code Variations</th>
									<th style="text-align: center; background-color: rgb(159, 195, 216);">Couleur</th>
									<th  style="text-align: center; background-color: rgb(159, 195, 216);">Taille</th>
									<th style="text-align: center; background-color: rgb(159, 195, 216);">quantité</th>
									<th style="text-align: center; background-color: rgb(159, 195, 216);">Montant</th>
								  </tr>
								</ng-template>
								<ng-template pTemplate="body" let-variationsAchats>
								  <tr style="text-align: center;">
									<td style="text-align: center;">{{variationsAchats.code_barre}}</td>
									<td style="text-align: center;">{{variationsAchats.Color}}</td>
									<td style="text-align: center;">{{variationsAchats.Size	}}</td>
									<td style="text-align: center;">
										<!-- {{variationsAchats.QTE}} -->
								<p-inputNumber [(ngModel)]="variationsAchats.QTE" mode="decimal"
								 (keyup)="onKeyUp($event,rowData,rowIndex,variationsAchats)"
								  (ngModelChange)="onQteChange(rowData,rowIndex,variationsAchats)"								   
								  [showButtons]="true" inputId="minmax-buttons" [min]="0" [max]="100"> </p-inputNumber>

									</td>
									<td style="text-align: center;">{{variationsAchats.QTE * rowData.prixttc | roundToThreeDecimals }}</td>
								  </tr>
								</ng-template>
							  </p-table>
							</div>
						  </td>
						</tr>
					  </ng-template>

					  <ng-template pTemplate="footer" let-Achats>
						<tr>
							<td colspan="3" class="text-right">Total quantité</td>
							<td>{{totalQuantity}}</td>
						</tr>
					</ng-template>
            </p-table>
		
				<!-- <h6>Facture Achats</h6> -->
				<!-- <p>Bonjour</p> -->
				<!-- Display Remise % and Remise value -->
				  <!-- Display HT and its value, Total fodec and its value on the same line -->

				 <div style="display: flex; margin-top: 30px; justify-content: flex-end;">

  <!-- Column 1: Remise% and Remise -->
  <!--<div style="flex: 0,5; border-right: 1px solid #b6b6b6; padding-right: 20px;">-->
  <!--  <div style="display: flex; margin-bottom: 10px;justify-content: space-between; ">-->
  <!--    <p style="font-weight: bold;">Remise %:</p>-->
  <!--    <div style="display: flex; align-items: center; margin-left: 30px;">-->
  <!--      <input [(ngModel)]="remisePercentage" type="number" style="width: 50px; color: blue; border-style: none;"  (keyup)="validateRemise($event)" (ngModelChange)="updateValues()" (input)="validateInput()" min="0" max="100" style = "margin-bottom: 20px;">-->
  <!--      <p style="margin-left: 5px;margin-bottom: 20px;">%</p>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <div style="display: flex; justify-content: space-between;">-->
  <!--    <p style="font-weight: bold;">Remise :</p>-->
  <!--    <p style="margin-left: 10px;">{{ remiseAmount | roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3'}}</p>-->
	 <!-- <span style="margin-right: 5px;">TND</span>-->

  <!--  </div>-->
  <!--</div>-->

  <!-- Column 2: HT, Taxes, TTC -->
  <div style="flex: 0,5; border-right: 1px solid #b6b6b6; padding: 0 20px;">
    <div style="display: flex; margin-bottom: 10px; justify-content: space-between;">
      <p style="font-weight: bold;">HT:</p>
      <p style="margin-left: 10px;">{{ htAmount| roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
	  <span style="margin-right: 5px;">TND</span>

    </div>
    <div style="display: flex; margin-bottom: 10px; justify-content: space-between;">
      <p style="font-weight: bold;">Taxes :</p>
      <p style="margin-left: 10px;">{{ taxesAmount| roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
	  <span style="margin-right: 5px;">TND</span>

    </div>
    <div style="display: flex; justify-content: space-between;">
      <p style="font-weight: bold;">TTC :</p>
      <p style="margin-left: 10px;">{{ ttcAmount| roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
	  <span style="margin-right: 5px;">TND</span>

    </div>
  </div>

  <!-- Column 3: Total Fodec, Timbre, Net a Payer -->
  <div style="flex: 0,5; padding-left: 20px;">
	<ng-container *ngIf="showFodec ">
    <div style="display: flex; margin-bottom: 10px; justify-content: space-between;">
      <p style="font-weight: bold;">Total Fodec {{fodec}}%:</p>
      <p style="margin-left: 10px;">{{ totalFodec| roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
	  <span style="margin-right: 5px;">TND</span>

    </div>
</ng-container>
    <div style="display: flex; margin-bottom: 10px; justify-content: space-between;">
      <p style="font-weight: bold;" *ngIf="timbreAmount>0">Timbre :</p>
	  
      <!-- <p style="margin-left: 10px;">{{ timbreAmount | currency: 'TND': 'symbol':'1.3-3' }}</p> -->
	  <input *ngIf="timbreAmount>0"
	  (keydown)="validateTimbre($event)"
	  [(ngModel)]="timbreAmount " 
	 [value]="timbreAmount | roundToThreeDecimals| number:'1.3-3'"
	 (ngModelChange)="updateValues()"
	 (input)="onInput($event)"
	 (change)="onChange($event)"
		type="number"  step="0.01" style="width: 50px; color: blue; border-style: none;" 
	   min="0" max="100" style = "margin-bottom: 20px;">
	</div>
	<!-- <span style="margin-right: 5px;">TND</span> -->

    <div style="display: flex; justify-content: space-between;">
      <p style="font-weight: bold;">Net a Payer :</p>
      <p style="margin-left: 20px;">{{ netToPayAmount| roundToThreeDecimals| currency: ' ': 'symbol':'1.3-3' }}</p>
	  <span style="margin-right: 5px;">TND</span>

    </div>
  </div>

</div>

<div style="display: flex; margin-top: 20px; justify-content: flex-end;">
  <button pButton pRipple type="button" label="Enregistrer" class="p-button-success mr-2 mb-" style="font-weight: bold; font-size: 20px;" (click)="AddAchat()"></button>
</div>

				  
			
		
			
        
			<div class="card flex justify-content-center">
				<!-- <p-paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" ></p-paginator> -->
			</div>
        </div></div></div>
<app-add-achat-article (dataSent)="ReceiveAchat($event)" [inputDetailsAchat]="DetailsAchatParent"></app-add-achat-article>
<app-confirmation-dialog (dataEvent)="receiveConfirm($event)"></app-confirmation-dialog>
