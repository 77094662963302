<p-dialog header="Header" [(visible)]="visible" [style]="{ width: '40vw' }"  (onHide)="close()" [modal]="true">
   
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Ajouter Fournisseur</span>
    </ng-template>  
	<div class="card p-fluid">
	
		<div class="field">
		  <label htmlFor="name">Nom Social<span class="required"> *</span></label>
		  <div class="input-container">
		  <input pInputText id="name" [formControl]="name" type="text" (input)="hideIcon('name')" (input)="valueChanged($event)" (keydown)="onKeyDown($event)"  />
		  <i *ngIf="isInvalid('name')" class="bi bi-exclamation-circle"></i>
		 
		  <!-- <i *ngIf="form.get('name').invalid && (form.get('name').dirty || form.get('name').touched)" class="bi bi-exclamation-circle"></i> -->

			<!-- <div *ngIf="form.get('name').hasError('required')">Le nom de fournisseur est requis.</div> -->
		  </div>
		  <div class="text-danger">
		  <small class="p-error" *ngIf="form.get('name').hasError('required') && showRequiredMsg">Champ obligatoire.</small>
		</div>
		  <!-- <div class="text-danger"
		  *ngIf="form.get('name').hasError('required') && showRequiredMsg">
		  Champ obligatoire.
	  </div> -->
		</div>
		<div class="field">
		  <label htmlFor="code">Code <span class="required"> *</span></label>
		  <div class="input-container">
		  <input pInputText id="code" [formControl]="code" type="text" (input)="hideIcon('code')"  (input)="valueChanged($event)" (keydown)="onKeyDown($event)" />
			<i *ngIf="isInvalid('code')" class="bi bi-exclamation-circle"></i>
		</div>
		<div class="text-danger">
		<small class="p-error"  *ngIf="form.get('name').hasError('required') && showRequiredMsg">Champ obligatoire.</small>
	</div>
		</div>
		<div class="field">
		  <label htmlFor="adresse">Adresse<span class="required"> *</span></label>
		  <div class="input-container">
		  <input pInputText id="adresse" [formControl]="adresse" type="text" (input)="hideIcon('adresse')" (input)="valueChanged($event)" (keydown)="onKeyDown($event)" />
		  <i *ngIf="isInvalid('adresse')" class="bi bi-exclamation-circle"></i>
</div>
<!-- <div class="text-danger"
		  *ngIf="form.get('adresse').hasError('maxlength')">
		  The maximum length for this field is 15 characters.
	  </div> -->
	  <div class="text-danger">
		<small class="p-error"  *ngIf="form.get('name').hasError('required') && showRequiredMsg">Champ obligatoire.</small>
	</div>
		</div>
		<div class="field">
		  <label htmlFor="tel">Téléphone</label>
		  <div class="input-container">
		  <input pInputText id="tel" [formControl]="tel" type="text" (input)="valueChanged($event)"  />
		  <!--<i *ngIf="isInvalid('tel')" class="bi bi-exclamation-circle"></i>-->
		  </div>
<!--<div class="text-danger"-->
<!--		  *ngIf="form.get('tel').hasError('maxlength') || form.get('tel').hasError('minlength') ">-->
		  <!--<small class="p-error"> nombre des chiffres maximum.</small>-->
	  <!--</div>-->
	 
	  <!-- <div class="text-danger"
		  *ngIf="form.get('tel').hasError('pattern')">
please enter numbers	  </div> -->
		</div>

		<div class="field">
		  <label htmlFor="mf">RC</label>
		  <div class="input-container">
		  <input pInputText id="mf" [formControl]="mf" type="text" (input)="hideIcon('mf')" (input)="valueChanged($event)" (keydown)="onKeyDown($event)" />
		  <i *ngIf="isInvalid('mf')" class="bi bi-exclamation-circle"></i>
</div>
		</div>
		
		<div class="field">
			<!-- <label htmlFor="codeTVA">Code TVA <span class="required"> *</span></label> -->
			<label htmlFor="codeTVA">Code TVA </label>

			<div class="input-container">
			<input pInputText id="codeTVA" [formControl]="codeTVA" type="text" (input)="hideIcon('codeTVA')"  (input)="valueChanged($event)"  (keydown)="onKeyDown($event)" />
			  <!-- <i *ngIf="isInvalid('codeTVA')" class="bi bi-exclamation-circle"></i> -->
			  <!-- <div *ngIf="isInvalid('codeTVA')">
				<p-messages severity="info" [value]="[{severity:'info', detail:'champ invalide !'}]"></p-messages>
			</div> -->
		  </div>
		  <!-- <div  class="text-danger">
			<small class="p-error"  *ngIf="form.get('name').hasError('required') && showRequiredMsg">Champ obligatoire.</small>
		</div> -->
		  </div>
	  </div>
	  
	
	  <div style="color:red; display: flex; align-items: center;">
		<i *ngIf="show" class=" bi-exclamation-circle "  style="flex-shrink: 0;color:red"></i>
		<span *ngIf="show" style="flex-shrink: 1;">{{ message }}</span>
	  </div>
	  <p-messages    [value]="msgs"></p-messages>

		  <ng-template pTemplate="footer">
        <!-- <button pButton pRipple    (click)="close()" label="annuler" class="p-button-danger"></button>
	
		<button pButton pRipple   (click)="createfourniss()"  label="confirmer" class="p-button-success"> </button>-->
			<button pButton pRipple label="Fermer" icon="pi pi-times" class="p-button-text" (click)="close()"></button>
                <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text" (click)="createfourniss()" ></button>
			
		
		
    </ng-template>
</p-dialog>