import { Component, OnInit, ViewChild } from '@angular/core';
import { FournisseurService } from 'src/app/demo/service/fournisseur.service';
import { AddFournisseurComponent } from '../fournisseur/add-fournisseur/add-fournisseur.component';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-list-fournisseurs',
  templateUrl: './list-fournisseurs.component.html',
  styleUrls: ['./list-fournisseurs.component.scss'],
  styles:[
`
th {
    text-align: center;
}
.field-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 5px;
}
th .sort-icon {
    margin-left: 40px; /* Adjust the margin to your preference */
}
.p-input-icon-left {
    float: right;
}
th {
    text-align: center;
}

`

  ]
})
export class ListFournisseursComponent implements OnInit {
  loadingTable:boolean
  loading
  fourniss
  search
  searchTerm
  sortOrder
  sortField
  selectAllCheckbox :boolean=false
  currentPage: number=1;
pageSize: number=10;
totalItems: number;
pageNumbers: number[];
currentpage
paginationInfo: any;
rowsPerPageOptions = [5, 10,20,25];
listFournissToSend
selectedFourniss:string[] = [];
  constructor(private service:FournisseurService , private messageService: MessageService) { }
  @ViewChild(AddFournisseurComponent)   myDialog:AddFournisseurComponent;
  @ViewChild(DeleteDialogComponent) myDeleteDialog: DeleteDialogComponent;

  ngOnInit(): void {
    // this.onPageChange({ page: 0, rows: this.pageSize });
  
  }


  onLazyLoad(event: LazyLoadEvent): void {
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    console.log( 'Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
    if(this.searchTerm){
      try {
        this.loading = true;
        this.service.getSort({ sortMode: this.sortOrder, sortFiled: this.sortField });
        this.service.getSearchedFournisseur(this.searchTerm).then(res=>{
        console.log(res)
      
        this.fourniss = res.data.map(x => ({
          id: x.id,
          name: x.attributes.name,
          code: x.attributes.code,
          adresse: x.attributes.adresse,
          tel: x.attributes.telephone,
          mf: x.attributes.mf,
          code_tva:x.attributes.code_tva,
          selected:false,
          date: new Date(x.attributes.createdAt).toLocaleDateString()
        }));
    
        this.paginationInfo = res.meta.pagination;
        this.currentPage = this.paginationInfo.page;
        this.pageSize = this.paginationInfo.pageSize;
        this.totalItems = this.paginationInfo.total;
        this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
    
        this.listFournissToSend = this.fourniss;
    
        console.log(this.fourniss);
        console.log("pagination info", this.paginationInfo);
      })
    } catch (error) {
      console.error('Error fetching fournisseurs:', error);
    } finally {
      this.loading = false; // Set loading to false after receiving the response or in case of an error
    }
    }
    else{
      this.service.getSort({ sortMode: this.sortOrder, sortFiled: this.sortField });
      this.getfourniss();
    }
    
    
    
    }

  getSearch(event){
    console.log(event.data);

    try {
      this.loading = true;
  
      let searchInputValues=[]
      searchInputValues.push(event.target.value);
      const searchData = searchInputValues.join(' ');

      this.service.getSearchedFournisseur(searchData).then(res=>{
        console.log(res)
      
        this.fourniss = res.data.map(x => ({
          id: x.id,
          name: x.attributes.name,
          code: x.attributes.code,
          adresse: x.attributes.adresse,
          tel: x.attributes.telephone,
          mf: x.attributes.mf,
          code_tva:x.attributes.code_tva,
          selected:false,
          date: new Date(x.attributes.createdAt).toLocaleDateString()
        }));
    
        this.paginationInfo = res.meta.pagination;
        this.currentPage = this.paginationInfo.page;
        this.pageSize = this.paginationInfo.pageSize;
        this.totalItems = this.paginationInfo.total;
        this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
    
        this.listFournissToSend = this.fourniss;
    
        console.log(this.fourniss);
        console.log("pagination info", this.paginationInfo);
      })
  
    
    } catch (error) {
     // console.error('Error fetching fournisseurs:', error);
    } finally {
      this.loading = false; // Set loading to false after receiving the response or in case of an error
    }

  }
  selectAll(event){
   
  this.fourniss.forEach(fourniss => {
    fourniss.selected = event.checked;
    if (event.checked) {
      this.selectedFourniss.push(fourniss.id);
    }
    else{
      this.selectedFourniss=[]
    }
  });

  console.log(this.selectedFourniss);

  }
  checkboxChanged(event, fourniss) {
    const fournissId = fourniss.id;

  if (event.checked) {
    this.selectedFourniss.push(fournissId);
  } else {
    const index = this.selectedFourniss.indexOf(fournissId);
    if (index > -1) {
      this.selectedFourniss.splice(index, 1);
    }
  }

  console.log(this.selectedFourniss);
  
  }

  async handleDeleteEvent(data) {
    try {
      if (data === "yes") {
        this.loading = true;
  
        for (const fournissID of this.selectedFourniss) {
          await this.service.deleteFourniss(fournissID).then(res => console.log("deleted fourniss", res));
          this.fourniss = this.fourniss.filter(fourniss => fourniss.id !== fournissID);
        }
  
        this.selectedFourniss = [];
        this.selectAllCheckbox = false;
        // this.currentPage=1

        
        await this.getfourniss();
      }
    } catch (error) {
      console.error('Error handling delete event:', error);
    } finally {
      this.loading = false;
    }
  }
  
  deleteFourniss(){
    this.myDeleteDialog.showDialog()
    // for(const fournissID of this.selectedFourniss){
    //   this.myDeleteDialog.showDialog()
    //   this.service.deleteFourniss(fournissID).then(res=> console.log("deleted fourniss",res))
    //   this.fourniss = this.fourniss.filter(fourniss => fourniss.id !== fournissID);

    // }
    // this.selectedFourniss=[]
  }
  async getfourniss() {
    try {
      this.loading = true;
  
      const data = await this.service.getFournisseur();
      console.log("data", data);
  
      this.fourniss = data.data.map(x => ({
        id: x.id,
        name: x.attributes.name,
        code: x.attributes.code,
        adresse: x.attributes.adresse,
        tel: x.attributes.telephone,
        mf: x.attributes.mf,
        code_tva:x.attributes.code_tva,
        selected:false,
        date: new Date(x.attributes.createdAt).toLocaleDateString()
      }));
  
      this.paginationInfo = data.meta.pagination;
      this.currentPage = this.paginationInfo.page;
      this.pageSize = this.paginationInfo.pageSize;
      this.totalItems = this.paginationInfo.total;
      this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
  
      this.listFournissToSend = this.fourniss;
  
      console.log(this.fourniss);
      console.log("pagination info", this.paginationInfo);
    } catch (error) {
      console.error('Error fetching fournisseurs:', error);
    } finally {
      this.loading = false; // Set loading to false after receiving the response or in case of an error
    }
  }
  
  async openDialog() {
    //  this.affiche=true
    // console.log( this.myDialog)
    // this.myDialog.toArray()[0].showDialog('top',true)
        this.myDialog.showDialog('top',true)

    }
    handleChildEvent(data:any){
      console.log(data)
this.fourniss=data

    }
    handleNotification(data) {
      console.log(data)
if(data==1){
  this.messageService.add({
    severity: 'success',
    summary: 'Successful',
    detail: 'Fournisseur créer avec succé',
    life: 3000
  });
}
if(data==2){
  this.messageService.add({
    severity: 'success',
    summary: 'Successful',
    detail: 'Fournisseur modifier avec succé',
    life: 3000
  });
}

    }
    receivePaginationChild(data){
      console.log(data)
      if(data){
        // this.onPageChange({ page: 0, rows: this.pageSize });
        this.currentPage=1
      }

    }

  editFournisseur(rowdata){
    // this.dataToSend=rowdata.id
  console.log(rowdata)
  this.myDialog.showDialog('top',true,rowdata)
    }
  async onPageChange(event) {
 if(!this.searchTerm){
  console.log(event)
      this.currentPage=event.page
      this.pageSize=event.rows
      console.log(this.currentPage)
   await this.service.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
 
    await this.getfourniss()

     if(this.selectAllCheckbox){

      this.fourniss.forEach(fourniss => {
        fourniss.selected = this.selectAllCheckbox;
        if (this.selectAllCheckbox) {
          this.selectedFourniss.push(fourniss.id);
        }
        else{
          this.selectedFourniss=[]
        }
      });
    
      console.log(this.selectedFourniss);
     }
 }
    else{
      this.currentPage=event.page
      this.pageSize=event.rows
      console.log( this.currentPage,this.pageSize)
      await this.service.sendData({currentPage:this.currentPage+1,pageSize:this.pageSize})
      try {
        this.loading = true;
    
      this.service.getSearchedFournisseur(this.searchTerm).then(res=>{
        console.log(res)
      
        this.fourniss = res.data.map(x => ({
          id: x.id,
          name: x.attributes.name,
          code: x.attributes.code,
          adresse: x.attributes.adresse,
          tel: x.attributes.telephone,
          mf: x.attributes.mf,
          code_tva:x.attributes.code_tva,
          selected:false,
          date: new Date(x.attributes.createdAt).toLocaleDateString()
        }));
    
        this.paginationInfo = res.meta.pagination;
        this.currentPage = this.paginationInfo.page;
        this.pageSize = this.paginationInfo.pageSize;
        this.totalItems = this.paginationInfo.total;
        this.pageNumbers = Array(this.paginationInfo.pageCount).fill(0).map((x, i) => i + 1);
    
        this.listFournissToSend = this.fourniss;
    
        console.log(this.fourniss);
        console.log("pagination info", this.paginationInfo);
      })
    } catch (error) {
      console.error('Error fetching fournisseurs:', error);
    } finally {
      this.loading = false; // Set loading to false after receiving the response or in case of an error
    }
      console.log("currentPage",this.currentPage,"pageSize", this.pageSize)

    }
  
     }
}
