<!-- <div class="grid">
	<div class="col-12"> -->
		<div class="card">
            <h4>Consultation Achats</h4>

            <div class="container">
               
                <p-multiSelect [(ngModel)]="selectedEtablissement " [options]="Etablissements"
                (onChange)="onEtablissementChange($event)" placeholder="Etablissements"
                optionLabel="name" [filter]="true" [style]="{ 'margin-right': '10px', 'max-width' : '200px' ,'width':'150px'}">
                 </p-multiSelect>

                <div *ngIf="selectedEtablissement && selectedEtablissement.length > 0">
                    <p-multiSelect [(ngModel)]="selectedStore" [options]="Depots"
                                  (onChange)="onStoreChange($event)" placeholder="Depot"
                                  optionLabel="name" [filter]="true" [style]="{ 'margin-right': '10px' , 'max-width' : '200px','width':'150px'}">
                    </p-multiSelect>
                  </div>
 
                        <p-multiSelect [(ngModel)]="selectedFournisseur" [options]="Fournisseurs"
                         (onFilter)="getfiltredFourValue($event)"
                                        (onChange)="onFournisseurChange($event)" placeholder="Fournisseur"
                                        optionLabel="name" [filter]="true" [style]="{ 'margin-right': '10px', 'max-width' : '200px','width':'150px' }">
                        </p-multiSelect>
                        
                        <!-- Replace p-autoComplete with p-multiSelect for selectedArticle -->
                       <p-multiSelect [(ngModel)]="selectedArticle" [options]="articles" (onChange)="onArticleChange($event)"
        (onFilter)="getfiltredValue($event)"  placeholder="Article" optionLabel="code_article_generique" [filter]="true"
          [style]="{ 'margin-right': '10px' , 'max-width' : '150px','min-width' : '150px'}">
        </p-multiSelect>
			
					<p-calendar class="drop"  [touchUI]="true" [readonlyInput]="true"
                    selectionMode="range"
                     [(ngModel)]="date" inputId="icon" placeholder="Calendrier" 
                    [style]="{ 'margin-right': '10px' , 'max-width' : '200px','width':'150px'}" > 
                    </p-calendar>
                    <button pButton pRipple type="button" 
                        icon="fa fa-filter bi bi-filter" 
                        label="Filtrer" 
                        class="p-button-success mr-0.5 mb-"
                        style="margin-right: 4px;"
                        (click)="buttonClicked = true; refreshPagination();filterachat()">
                    </button>

                    <button pButton pRipple type="button" 
                            icon="bi bi-arrow-clockwise" 
                            label="Refresher" 
                            class="btn btn-primary mr-0.5 mb-" 
                            style="margin-right: 4px; background-color: rgba(0, 78, 156, 0.158); color: black;"
                            (click)="refreshPagination();Refreshachat()"></button>
                           
					<!-- <button pButton pRipple type="button" 
                        icon="fa fa-filter bi bi-filter" 
                        label="Filtrer" 
                        class="p-button-success mr-0.5 mb-"
                        style="margin-right: 4px;"
                        [iconPos]="isPageMinimized() ? 'right' : 'left'"
                        (click)="filterVentes()">
                    </button>

                    <button pButton pRipple type="button" 
                            icon="bi bi-arrow-clockwise" 
                            label="Refresher" 
                            class="btn btn-primary mr-0.5 mb-" 
                            style="margin-right: 4px; background-color: rgba(0, 78, 156, 0.158); color: black;"
                            [iconPos]="isPageMinimized() ? 'right' : 'left'"
                            (click)="resetFilters()">
                    </button> -->

                </div>           
<p-table 
[columns]="cols" 
[value]="Achats" 
[globalFilterFields]="['Nom']"
 dataKey="id"
responsiveLayout="stack"
scrollHeight="flex"
responsive="false" 
[lazy]="true"
(onLazyLoad)="onLazyLoad($event)" >
			

                        
    <ng-template pTemplate="header" let-columns>
        
        <tr>
            <th></th>
            <th *ngFor="let col of columns" pSortableColumn="{{col.field}}">

                <div  *ngIf="col.field=='num'"class="flex justify-content-between align-items-center">
                    {{ col.header }}
                    <p-sortIcon field="id"></p-sortIcon>
                </div>
                <div  *ngIf="col.field=='date'"class="flex justify-content-between align-items-center">
                    {{ col.header }}
                    <p-sortIcon field="{{col.field}}"></p-sortIcon>
                </div>
                <div  *ngIf="col.field=='qte'"class="flex justify-content-between align-items-center">
                    {{ col.header }}
                    <p-sortIcon field="{{col.field}}"></p-sortIcon>
                </div>
                <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
                {{ col.header }}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>
            </div>

            <div  *ngIf="col.field=='fournisseurName'"class="flex justify-content-between align-items-center">
                {{ col.header }}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>
            </div>


            <div  *ngIf="col.field=='etablissement'"class="flex justify-content-between align-items-center">
                {{ col.header }}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>
            </div>
            
            <div  *ngIf="col.field=='depot'"class="flex justify-content-between align-items-center">
                {{ col.header }}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>

            </div>
            <div  *ngIf="col.field=='prixnet'"class="flex justify-content-between align-items-center">
                {{ col.header }}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>

            </div>
            <div  *ngIf="col.field=='Remise'"class="flex justify-content-between align-items-center">
                {{ col.header }}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>

            </div>
            <div  *ngIf="col.field=='timbre'"class="flex justify-content-between align-items-center">
                {{ col.header }}
                <p-sortIcon field="{{col.field}}"></p-sortIcon>

            </div>
            <div  *ngIf="col.field=='ht'"class="flex justify-content-between align-items-center">
                {{ col.header }}

                <p-sortIcon field="{{col.field}}"></p-sortIcon>
            </div>
            <div  *ngIf="col.field=='taxes'"class="flex justify-content-between align-items-center">
                {{ col.header }}

                <p-sortIcon field="{{col.field}}"></p-sortIcon>
            </div>
            <div  *ngIf="col.field=='ttc'"class="flex justify-content-between align-items-center">
                {{ col.header }}

                <p-sortIcon field="{{col.field}}"></p-sortIcon>
            </div>
            <div  *ngIf="col.field=='status'"class="flex justify-content-between align-items-center">
                {{ col.header }}

            </div>
            <div  *ngIf="col.field=='action'"class="flex justify-content-between align-items-center">
                {{ col.header }}
            </div>
           
            </th>
            
            
            
        </tr>
    </ng-template>


    <ng-template pTemplate="body" let-rowData  let-columns="columns" let-product let-ri="rowIndex">
        <tr  [pEditableRow]="columns">

            <td>
                <!-- <div class="field-checkbox">
                    <p-checkbox
                    [binary]="true"
                    name="checkbox{{rowIndex}}"
                    [(ngModel)]="product.selected"

                    (onChange)="checkboxChanged($event, product)"
                   ></p-checkbox>
                </div> -->
            </td>
            <td style="min-width: 12rem;" *ngFor="let col of columns">

                
            <div  *ngIf="col.field=='date'"class="flex justify-content-between align-items-center">
                
                    {{rowData[col.field] }}
            </div>

        
            <div  *ngIf="col.field=='num'"class="flex justify-content-between align-items-center">
                
                {{rowData[col.field] }}
            </div>

            <div  *ngIf="col.field=='qte'"class="flex justify-content-between align-items-center">
                        
                        {{rowData[col.field] }}
                </div>

            <div  *ngIf="col.field=='code_article_generique'"class="flex justify-content-between align-items-center">
                
                    {{rowData[col.field] }}
            </div>

            <div  *ngIf="col.field=='fournisseurName'"class="flex justify-content-between align-items-center">
                        
                {{rowData[col.field]}}
            </div>

                <div  *ngIf="col.field=='etablissement'"class="flex justify-content-between align-items-center">
                                
                    {{rowData[col.field]}}
            </div>

            <div  *ngIf="col.field=='depot'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field]}}
            </div>

            <div  *ngIf="col.field=='Remise'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field]}}
            </div>
            <div  *ngIf="col.field=='timbre'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field]}}
            </div>
            <div  *ngIf="col.field=='ht'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field] | roundToThreeDecimals }}
            </div>
            <div  *ngIf="col.field=='taxes'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field] | roundToThreeDecimals }}
            </div>
            <div  *ngIf="col.field=='ttc'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field] | roundToThreeDecimals }}
            </div>
            <div  *ngIf="col.field=='prixnet'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field] | roundToThreeDecimals }}
            </div>
            <div  *ngIf="col.field=='status'"class="flex justify-content-between align-items-center">
                                
                {{rowData[col.field]}}
            </div>
            <div  *ngIf="col.field=='action'"class="flex justify-content-between align-items-center">
                                
               <a (click)="showDetails(rowData,ri)"><i class="bi bi-eye-fill" style="width: 90%;"></i>  </a>          
             </div>

           
            </td>
           
        
        </tr>
            
        </ng-template>
        <ng-template pTemplate="footer" let-Achats>
            <tr>
                <td colspan="3" class="text-right">qantité Total:</td>
                <td>{{totalQte }}</td>
                <td colspan="3" class="text-right">Total TTC:</td>
                <td>{{totalTTC | roundToThreeDecimals}}<span style="margin-right: 5px;"> TND</span>
                </td>
            </tr>
        </ng-template>

    
    
</p-table>
<div class="card flex justify-content-center">
    <p-paginator #paginator (onPageChange)="onPageChange($event)" 
     [totalRecords]="totalItems" 
     [rows]="pageSize"
     [rowsPerPageOptions]="rowsPerPageOptions" ></p-paginator>
</div>
</div>
<!-- </div>
</div> -->
<app-consulte-details-achat ></app-consulte-details-achat>
<div *ngIf="loading" class="splash-screen">
    <div  class="spinner">
        <span class="ball-1"></span>
        <span class="ball-2"></span>
        <span class="ball-3"></span>
        <span class="ball-4"></span>
        <span class="ball-5"></span>
        <span class="ball-6"></span>
        <span class="ball-7"></span>
        <span class="ball-8"></span>
    </div>
</div>