
import { throwError } from "rxjs";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { environment } from "./../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class FournisseurService {
  pageSize=10
  currentPage=0
  loading
  sortFiled
  sortMode
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) {

   }
      //**************************************handle errrors***************************************** */
      private handleApiError(error) {
        // Handle API error status here
        console.error('API call failed:', error);
      
        if (error.status !== 200) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
          this.isToastShown = true;
          setTimeout(() => {
            this.isToastShown = false;
          }, 5000);
        } 
        
      }
         //******************************************************************************* */
    
   async  sendData(data: any) {

    this.currentPage=data.currentPage
    this.pageSize=data.pageSize

  }

  async  getSort(data: any) {
    this.sortMode=data.sortMode
    this.sortFiled=data.sortFiled
    console.log( this.sortMode)
    
    }
   async getFournisseur() {
    let promise = new Promise<any>((resolve, reject) => {
      const params = new HttpParams()
      .set('sort', 'id:DESC')
      .set('pagination[pageSize]',this.pageSize)
      .set('pagination[page]', this.currentPage)
      
      let url = `${environment.url}/fournisseurs?`

      let filed
      if (this.sortFiled) {
  
        switch (this.sortFiled) {
          case 'name':
            filed = 'name';
            break;
          case 'code':
            filed = 'code';
            break;
          case 'adresse':
            filed = 'adresse';
            break;
          case 'tel':
            filed = 'telephone';
            break;
            case 'mf':
              filed = 'mf';
              break;
          case 'code_tva':
            filed = 'code_tva';
            break;
       
          default:
            break;
        }
      }

      if (this.sortMode === 1) {
        url += `&sort=${filed}:DESC`;
      } else if (this.sortMode === -1) {
        url += `&sort=${filed}:ASC`;
      }
  
      this.http
        .get(url,{params})
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getFournisseurWithContain(data?) {
    let promise = new Promise<any>((resolve, reject) => {
      const searchTerm = data ? data : '';

      const attribute = 'name';
      let url = `${environment.url}/fournisseurs?filters[${attribute}][$contains]=${searchTerm}`
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }
  async getFourniss() {
    let promise = new Promise<any>((resolve, reject) => {
      
      
      let url = `${environment.url}/fournisseurs?`
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  }




  async createfourniss(data) {

 
      let promise = new Promise<any>((resolve, reject) => {
        const params = new HttpParams()
        .set('pagination[pageSize]',this.pageSize)
        .set('pagination[page]', this.currentPage)
        let url = `${environment.url}/fournisseurs?`
        this.http
          .post(url,{data},{params})
          .toPromise()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            this.handleApiError(err);
            throw err;           });
      });
      return promise;
    
    //return this.http.post<any>(environment.url+"fournisseurs" , {data});
  }
  async Updatefourniss(id,data) {

 
    let promise = new Promise<any>((resolve, reject) => {
      let url = `${environment.url}/fournisseurs/`+id
      this.http
        .put(url,{data})
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;         });
    });
    return promise;
  
  //return this.http.post<any>(environment.url+"fournisseurs" , {data});
}
deleteFourniss(id){
  // this.loading = true;

  let promise = new Promise<any>((resolve, reject) => {
    let url = `${environment.url}/fournisseurs/`+id
    this.http
      .delete(url)
      .toPromise()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        this.handleApiError(err);
        throw err;       })
      // .finally(() => {
      //   // Set loading to false regardless of success or error
      //   this.loading = false;
      // });
  });
  return promise;
}
async getFournisseurById(id) {
  let promise = new Promise<any>((resolve, reject) => {
    
    
    let url = `${environment.url}/fournisseurs/${id}`
    this.http
      .get(url)
      .toPromise()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        this.handleApiError(err);
        throw err;       });
  });
  return promise;
}
async getSearchedFournisseur(data: string | undefined) {
  let promise = new Promise<any>((resolve, reject) => {
    console.log( this.currentPage,this.pageSize)

    const params = new HttpParams()
    .set('pagination[pageSize]',this.pageSize)
    .set('pagination[page]', this.currentPage)
    let url = `${environment.url}/fournisseurs`
    if (data) {
      url += `?filters[$or][0][name][$containsi]=${data}&filters[$or][1][code][$containsi]=${data}`;
    }
    let filed
    if (this.sortFiled) {

      switch (this.sortFiled) {
        case 'name':
          filed = 'name';
          break;
        case 'code':
          filed = 'code';
          break;
        case 'adresse':
          filed = 'adresse';
          break;
        case 'tel':
          filed = 'telephone';
          break;
          case 'mf':
            filed = 'mf';
            break;
        case 'code_tva':
          filed = 'code_tva';
          break;
     
        default:
          break;
      }
    }

    if (this.sortMode === 1) {
      url += `&sort=${filed}:DESC`;
    } else if (this.sortMode === -1) {
      url += `&sort=${filed}:ASC`;
    }
    this.http
      .get(url,{params})
      .toPromise()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
            });
  });
  return promise;
}
}
