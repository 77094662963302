<div class="grid">
	<div class="col-12">
		<div class="card">
			<p-toast></p-toast>

            <h3>Consultation Stock</h3>



		<div class="container">
		<!--------------------etab et store -->
				  <div  style="display: flex; flex-direction: column; margin-top : 15px ;">
					<div class="field" style="margin-right: 10px;">
						
						<p-multiSelect
						[(ngModel)]="selectedEtablissement"
						placeholder="Etablissements"
						(onFilter)="filterEtablissements($event)"
						[options]="Etablissements" 
						optionLabel="name"
						(onChange)="SelectEtabliss($event)"
						></p-multiSelect>
					  </div>
					  <div class="field" style="margin-right: 10px;">
					
						<p-multiSelect
						 placeholder="Depots"
						inputId="multiselect"
						class="custom-ms" 
						[options]="Depots"
						[(ngModel)]="selectedDepot"
						optionLabel="name" 
						(onChange)="SelectDepots($event)" 
						(onFilter)="filterEtablissements($event)"
							 ></p-multiSelect>
					  </div>
					 
				</div>
		<!--------------------articles et fournisseur -->	
					  <div  style="display: flex; flex-direction: column; margin-top : 15px ;">
					  <div class="field" style="margin-right: 10px;">
						
						<p-multiSelect
							placeholder="Articles"
							inputId="multiselect"
							class="custom-ms"
							[options]="Articles"
							[(ngModel)]="selectedArticle"
							(onChange)="SelectArticles($event)"
							(onFilter)="filterArticlesWithSearch($event)"
							optionLabel="codeArticle"
						></p-multiSelect>
					  </div>
					  <div class="field" style="margin-right: 10px;">
						<p-multiSelect
							placeholder="Fournisseurs" 
							inputId="multiselect"
							class="custom-ms"
							[options]="Fournisseurs"
							[(ngModel)]="selectedFournisseurs" 
							(onChange)="SelectFournisseur($event)"
							(onFilter)="filterFournisseurWithSearch($event)"
							optionLabel="nom">
						</p-multiSelect>
					  </div>
				
		</div>

		<!--------------------Radio 1 -->	

				<div style="display: flex; flex-direction: column;">
					<p-divider style="width: 200px; margin: -10px 0;">Depot / Stock</p-divider>
					<div class="field">
						<div class="grid" style="margin-top: 3px;">
							<div class="col-12">
								<div class="field-checkbox">
								<p-radioButton
									name="groupingOption"
									[value]="true"
									[(ngModel)]="GroupingOption"
									(onClick)="RadioButtonClick(true);refreshPagination(); getStocks();getDisplayedColumns()"
								></p-radioButton>
								<label for="dimensionne">Regrouper par depot</label>
								</div>
							</div>
							</div>
							<div class="grid" style="margin-bottom: -20px;">
							<div class="col-12">
								<div class="field-checkbox">
								<p-radioButton
									name="groupingOption"
									[value]="false"
									[(ngModel)]="GroupingOption"
									(onClick)="RadioButtonClick(false);refreshPagination(); getStocks();getDisplayedColumns()"
								></p-radioButton>
								<label for="dimensionne">Regrouper par somme stock</label>
								</div>
							</div>
						</div>
					</div>
				</div>

		<!--------------------Radio 2 -->

				<!-- <div style="display: flex; flex-direction: column;">
					<p-divider style="width: 200px; margin: -10px 0;">Article / Variation</p-divider>
					<div class="field">
						<div class="grid" style="margin-top: 3px;">
							<div class="col-12">
							<div class="field-checkbox">
								<p-radioButton
								name="groupingOption"
								[value]="true"
								[(ngModel)]="selectedGroupingOption"
								(change)="onRadioButtonChange(true)"
								(click)="onRadioButtonClick(false);refreshPagination();getStocks();getDisplayedColumns()"
								></p-radioButton>
								<label for="dimensionne">Regrouper par article</label>
							</div>
							</div>
						</div>
						<div class="grid" style="margin-bottom: -20px;">
							<div class="col-12">
							<div class="field-checkbox">
								<p-radioButton
								name="groupingOption"
								[value]="false"
								[(ngModel)]="selectedGroupingOption"
								(change)="onRadioButtonChange(false)"
								(click)="onRadioButtonClick(false);refreshPagination();getStocks();getDisplayedColumns()"
								></p-radioButton>
								<label for="dimensionne">Regrouper par variation</label>
							</div>
							</div>
						</div>
					</div>
				</div> -->

  	 	<!--------------------coleur taille  -->

				<!-- <div style="display: flex; flex-direction: column;margin-top : 15px ;">
					<div class="grid" style="margin-right: 10px;">
					  <div class="col-12">
						<p-multiSelect 
						inputId="multiselect" 
						placeholder="Taille" 
						[disabled]="selectedGroupingOption" 
						class="custom-ms" [options]="Tailles" 
						[(ngModel)]="selectedTaille" 
						optionLabel="nom"
						(onFilter)="filterTaillesWithSearch($event)" 
						(onChange)="SelectTailles($event)"></p-multiSelect>
					  </div>
					</div>
					<div class="grid" style="margin-right: 10px;">
					  <div class="col-12">
						<p-multiSelect inputId="multiselect" placeholder="Couleurs" [disabled]="selectedGroupingOption" class="custom-ms" [options]="Couleurs" [(ngModel)]="selectedCouleur" optionLabel="nom" (onFilter)="filterCouleursWithSearch($event)" (onChange)="SelectCouleurs($event)"></p-multiSelect>
					  </div>
					</div>
				</div> -->

		<!--------------------stock et button -->

				<div style="display: flex; flex-direction: column;">
					<div class="container1" style="display: flex; flex-direction: column; margin-right: 20px; margin-top: 
					20px;">
						<div class="control-container1">
						<div class="stock-checkbox-container1">
							<p-checkbox [binary]="true" [(ngModel)]="checkedEnStock" (ngModelChange)=" refreshPagination();
							getOnlyArticleInStock()" ></p-checkbox>
							<label class="stock-label">Article En stock</label>
						</div>
						<div class="button-container1">
							<button pButton pRipple type="button" icon="bi bi-arrow-clockwise" label="Refresh" class="btn btn-primary mr-0.5 mb-" (click)="onRefreshButtonClick()" style="margin-right: 4px; background-color: rgba(0, 78, 156, 0.158); color : black;"></button>
							<button pButton pRipple type="button" icon="fa fa-filter bi bi-filter" label="Filtrer" class="p-button-success mr-0.5 mb-" (click)=" refreshPagination();
							FilterWithButtonClick()"></button>
						</div>
						</div>
					</div>
				</div>
			  </div>
			  

<!-- ----------------------------------- TaBLE ----------------------------------------------- -->
        
            <p-table
			 [columns]="cols"
			 [value]="Stocks"
			 [globalFilterFields]="['Nom']"
			 dataKey="id"
			 class="centered-table" responsive="false" 
			 responsiveLayout="stack" scrollHeight="flex" 
			 [lazy]="true"
			(onLazyLoad)="onLazyLoad($event)"
			 >
                <ng-template pTemplate="header" let-columns >
					<tr>
						<th *ngFor="let col of columns"  class="centered-header" pSortableColumn="{{col.field}}">
							<div  *ngIf="col.field=='article'"class="flex justify-content-between align-items-center">
								{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>
							</div>
							<div  *ngIf="col.field=='depot'"class="flex justify-content-between align-items-center">
								{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>
							</div>
							<div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
								{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>
							</div>
							<div  *ngIf="col.field=='physique'"class="flex justify-content-between align-items-center">
								{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>
							</div>
							<div  *ngIf="col.field=='achat'"class="flex justify-content-between align-items-center">
								{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>
							</div>
							<div  *ngIf="col.field=='color' && !selectedGroupingOption"class="flex justify-content-between align-items-center">
								{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>
							</div>
							<div  *ngIf="col.field=='taille' && !selectedGroupingOption"class="flex justify-content-between align-items-center">
								{{ col.header }}<p-sortIcon field="{{col.field}}"></p-sortIcon>
							</div>
						</th>
					</tr>
				</ng-template>
						<ng-template pTemplate="body" let-rowData  let-columns="columns" let-product let-ri="rowIndex">
							<tr  [pEditableRow]="columns">
								<td style="min-width: 12rem;" *ngFor="let col of columns">
									<div  *ngIf="col.field=='article'"class="flex justify-content-between align-items-center">
									{{rowData[col.field] }}
									</div>
									<div  *ngIf="col.field=='depot'"class="flex justify-content-between align-items-center">
									{{rowData[col.field] }}
									</div>
									<div  *ngIf="col.field=='fournisseur'"class="flex justify-content-between align-items-center">
									{{rowData[col.field]}}
									</div>
									<div  *ngIf="col.field=='physique'"class="flex justify-content-between align-items-center">
									{{rowData[col.field]}}
									</div>
									<div  *ngIf="col.field=='achat'"class="flex justify-content-between align-items-center">
									{{ rowData[col.field] }}
									</div>
									<div  *ngIf="col.field=='color' && !selectedGroupingOption"class="flex justify-content-between align-items-center">
									{{ rowData[col.field] }}
									</div>
									<div  *ngIf="col.field=='taille' && !selectedGroupingOption"class="flex justify-content-between align-items-center">
										{{ rowData[col.field] }}
									</div>
								</td>
							</tr>
										
						</ng-template>

						<ng-template pTemplate="footer" let-product  let-columns="columns">
							<tr>
								<td >Total physique:</td>
								<td >{{totalPhys}}</td>
								<td  class="text-right">Total Achat:</td>
								<td>{{totalAchat}}</td>
								<!-- <td *ngFor="let col of columns" [attr.colspan]="getColspan(col.field)" class="text-right">
									{{ getFooterText(col.field) }}
								</td> -->
							</tr>
						</ng-template>
            </p-table>

		
<!-- ----------------------------------- paginator et splash screen ----------------------------------------------- -->
			
				<div class="card flex justify-content-center">
					<p-paginator #paginator (onPageChange)="onPageChange($event)"  [totalRecords]="totalItems" [rows]="pageSize" [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
				</div>
        	</div></div></div>
			<div *ngIf="loading" class="splash-screen">
				<div  class="spinner">
					<span class="ball-1"></span>
					<span class="ball-2"></span>
					<span class="ball-3"></span>
					<span class="ball-4"></span>
					<span class="ball-5"></span>
					<span class="ball-6"></span>
					<span class="ball-7"></span>
					<span class="ball-8"></span>
				</div>
			</div>

<br><br>
<app-delete-dialog></app-delete-dialog>

<div *ngIf="loading" class="splash-screen">
    <div  class="spinner">
        <span class="ball-1"></span>
        <span class="ball-2"></span>
        <span class="ball-3"></span>
        <span class="ball-4"></span>
        <span class="ball-5"></span>
        <span class="ball-6"></span>
        <span class="ball-7"></span>
        <span class="ball-8"></span>
    </div>
</div>