import { Injectable } from '@angular/core';
import { environment } from "./../../../environments/environment";
import { HttpClient ,HttpParams} from "@angular/common/http";
import { MessageService } from 'primeng/api';
import { ModReglement, StoreAttributes, StoreData, TicketAttributes, TicketZ } from 'src/app/models/TicketZ';

@Injectable({
  providedIn: 'root'
})
export class TicketZserviceService {
   isToastShown: boolean = false;
    total : number = 0;
  constructor(private http: HttpClient, private messageService: MessageService) { 

    

  }
   handleApiError(error) {
    // Handle API error status here
    console.error('API call failed:', error);
  
    if (error.status !== 200) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
      this.isToastShown = true;
      setTimeout(() => {
        this.isToastShown = false;
      }, 5000);
    } 
  }

  formatDateToYYYYMMDD(date : any) {

    date.setDate(date.getDate() + 1);
    return date.toISOString();
    
  } 

  StrapiFilterDate(date : any[] , count , datefield : string): string {
    let filter = '';
    if (Array.isArray(date)) {

      if (date.length === 2 && date[1] != null) {
        const startDate = this.formatDateToYYYYMMDD(date[0]);
        const endDate = this.formatDateToYYYYMMDD(date[1]);
        filter += `&filters[$and][${count}][${datefield}][$gte]=${startDate}`
        count += 1;
        filter += `&filters[$and][${count}][${datefield}][$lte]=${endDate}`;
      } else if (date.length === 2 && date[1] == null) {
        const formattedDate = this.formatDateToYYYYMMDD(date[0]);
        filter += `&filters[$and][${count}][${datefield}][$eq]=${formattedDate}`;
      } else {
        console.error('Invalid date range:', date);
      }
    } else {
      const formattedDate = this.formatDateToYYYYMMDD(date);
      filter += `&filters[$and][${count}][${datefield}][$eq]=${formattedDate}`;
    }
    console.log('filter is ' , filter);
    return filter
  }


  getTicektZ(page ?: number, size ?: number , field?: string , option? : number , date?  , datefield? ,add? ) {
    const sortOrder = option === 1 ? 'ASC' : 'DESC';
    let sort = field && option ? `sort=${field}:${sortOrder}&` :  '';
    const pagin = `pagination[page]=${page}&pagination[pageSize]=${size}`;
    let GetApi = `${environment.url}/ticketzs?populate=store&${pagin}&${sort}`
    add ? GetApi = `${GetApi}${add}` : '';
    date ?  GetApi = `${GetApi}${this.StrapiFilterDate(date, 0 , datefield)}` : '' ;
    return new Promise<TicketZ[]>(async (resolve, reject) => {
      try {
        const response = await this.http.get<any>(GetApi).toPromise();
        this.total = response.meta.pagination.total
        const ticketZs: TicketZ[] = response.data.map((ticketData: any) => {
          const attributes = ticketData.attributes;
          const storeAttributes = attributes.store.data.attributes;
          const storeData = new StoreData(new StoreAttributes(
            storeAttributes.id,
            storeAttributes.name,
            storeAttributes.code,
            storeAttributes.createdAt,
            storeAttributes.updatedAt,
            storeAttributes.code_facture
          ));
          const ticketAttributes = new TicketAttributes(
            attributes.date_start,
            attributes.date_fin,
            attributes.numero,
            storeData,
            attributes.operation.ch,
            attributes.operation.qty,
            attributes.nbTicket,
            attributes.modReglement.map((modReg: any) => new ModReglement(modReg.code, modReg.name, modReg.qty, modReg.ch))
          );
          return new TicketZ(ticketData.id, ticketAttributes);
        });
        resolve(ticketZs);
      } catch (error) {
        // Handle error
        console.error('Error fetching ticketZs:', error);
        reject(error);
      }
    });
  }
}

  


