import { Component, OnInit  ,ViewChild } from '@angular/core';
import { VenteService } from 'src/app/demo/service/vente.service';
import { DetailsTicketComponent } from '../details-ticket/details-ticket.component';
import { ArticleService } from 'src/app/demo/service/article.service';
import { FactureServicesService } from 'src/app/demo/service/facture-services.service';
import { ShareticketService } from 'src/app/demo/service/shareticket.service';
import { GenererVenteService } from 'src/app/demo/service/generer-vente.service';
import { LazyLoadEvent } from 'primeng/api';
import {FactureTicketPDFComponent} from  '../facture-ticket-pdf/facture-ticket-pdf.component';
import { Router, NavigationExtras } from '@angular/router';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
interface factureitem {
  id: number;
  numero_facture: string;
  date_facture: string;
  client
  netpaye
  store
}
interface Facture {
  id: number;
  attributes: {
    numero_facture: string;
    date_facture: string;
    prix: number;
    createdAt: string;
    updatedAt: string;
    name: null | string;
    companyName: null | string;
    client_name: null | string;
    clientcode: null | string;
    client_address: null | string;
    client_phone: null | string;
    quantiteTotal: null | string;
    sumoffodec: null | string;
    timbre: null | string;
    fodec: null | string;
    sumOfTaxes: null | string;
    sumOfHt: null | string;
    netpaye: null | string;
    company_address: null | string;
    company_registrationNumber: null | string;
    company_rc: null | string;
    company_phone: null | string;
    company_fax: null | string;
    company_email: null | string;
    company_taxIdentification: null | string;
    company_routeAddress: null | string;
    company_imageUrl: null | string;
    ventes: {
      data: Vente[];
    };
    client: {
      data: {
        id: number;
        attributes: {
          name: string;
          code: string;
          adresse: string;
          uuid: string;
          telephone: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    };
    etablissement: {
      data: {
        id: number;
        attributes: {
          name: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    };
    etablissement_facture: {
      data: {
        id: number;
        attributes: {
          name: string;
        //   code: string;
        //   createdAt: string;
        //   updatedAt: string;
        };
      };
    };
    store: {
      data: {
        id: number;
        attributes: {
          name: string;
          code: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    };
    produit_factures: {
      data: ProduitFacture[];
    };
  };
}

interface ProduitFacture {
  id: number;
  attributes: {
    createdAt: string;
    updatedAt: string;
    reference: string;
    designation: string;
    quantite: number;
    prix_uht: number;
    tva: number;
    prix_ttc: number;
    totale_ht: number;
  };
}

interface Vente {
  id: number;
  pourcentage_remise: number;
  remise: number;
  ht: number;
  taxes: number;
  ttc: number;
  total_fodec: number;
  timbre: number;
  prix_net: number;
  uuid: string;
  code_facture_client: string;
  date_vente: string;
  client : string ;
  store : string ;
  etablissement : string ;
  selected?: boolean;
  product?: string ;
  designation? : string ;
  clientcode : string ;
  clientadress: string ;
  clienttelephone 
}
@Component({
  selector: 'app-consultation-facture',
  templateUrl: './consultation-facture.component.html',
  styleUrls: ['./consultation-facture.component.scss']
})
export class ConsultationFactureComponent implements OnInit {
  @ViewChild(FactureTicketPDFComponent) factureDialog: FactureTicketPDFComponent; 
  factureitems: factureitem[] = [];
  factures: Facture[] = [];
  ventes: Vente[] = [];
  filteredVentes: Vente[] = [];
  selectedVentes: Vente[] = [];
  selectAllCheckbox: boolean = false;
  date
  Etablissements
  Depots : any[] = [];
  Clients
  selectedEtablissement: any[] = [];
  selectedStore
  selectedClient
  filtredClient
  filtredStore
  filtredEtablissement
  selectedArticle
  articles
  filteredArticle
  pageMinimized = false;
  fieldsEmpty: boolean = false;
  loading = false;
  first = 1
  last = 5
  totalRecords 
  TotalNet = 0 ;
  page=1
  size=5
  sortField: string | undefined;
  sortOrder: number | undefined;
  selectedEtabFact
  filtredEtabFact
  EtabFact
  pageLinkSize 


  constructor( private router: Router , private factureService: FactureServicesService ,private venteService:VenteService , private articleService:ArticleService, private sharedService: ShareticketService ,private GenerVente :GenererVenteService) { }


  //---------------- on change----------------//

  onStoreChange(event: any) {
    // Handle the change in selectedStore
    console.log('Selected Store:', this.selectedStore);
    // Add additional logic as needed
  }

  onClientChange(event: any) {
    // Handle the change in selectedClient
    console.log('Selected Client:', this.selectedClient);
    // Add additional logic as needed
  }

  onArticleChange(event: any) {
    // Handle the change in selectedArticle
    console.log('Selected Article:', this.selectedArticle);
    // Add additional logic as needed
  }

  onEtablissementChange(event: any) {
   
    this.Depots = [] ;

  for ( let etablissement of this.selectedEtablissement) {
    // Use optional chaining to safely access the 'stores' property
    for ( let store of etablissement.stores){
      this.Depots.push(store) ;
    }

    console.log('sf',this.Depots)
  }
  
    console.log('feffe',this.selectedEtablissement)
  }


  //------------------- oninit --------------------//

  ngOnInit(): void {
      
    this.getData();
  }
  
  async getEtabFact(){
      await this.venteService.getEtabFact().then(res=>{
        
        console.log(res)
      this.EtabFact = res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
        store=>({id:store.id,name:store.attributes.name})
      )}))})
      console.log('etabbbb is q:: : ',this.EtabFact)
    }

  async getData() {
       this.getEtabFact()
    await this.getEtablissement().then(() => {
      if (this.Etablissements && this.Etablissements.length > 0) {
       
        setTimeout(() => {
          this.selectedEtablissement = [this.Etablissements[0]];
          for ( let etablissement of this.selectedEtablissement) {
            // Use optional chaining to safely access the 'stores' property
            for ( let store of etablissement.stores){
              this.Depots.push(store) ;
            }}
       });
       
      }
      
    });
    this.getClients();
    this.getArticles();
    // this. getfacture();
    
}

onetabfactChange (event : any) {
   console.log('Selected etab:', this.selectedEtabFact);
}

async getfacture(){
    this.loading = true ;
  this.factureitems = [];
  try {
     await this.factureService.getFacture(this.page, this.size , this.sortField , this.sortOrder ,null,null,null,this.date ,this.selectedEtabFact,this.selectedClient,this.selectedArticle).then((items : any) => {
      items.data.map((item: Facture) => {
    console.log('facture etab : ', item.attributes)
      const factitem: factureitem = {
        id: item.id,
        numero_facture: item.attributes.numero_facture,
        date_facture: item.attributes.date_facture,
        client: item.attributes.client?.data?.attributes?.name || null,
        netpaye: item.attributes.netpaye || null,
        store: item.attributes.etablissement_facture?.data?.attributes?.name || null,
      };
      this.factureitems.push(factitem);
});})
    console.log('this.factures ;', this.factureitems );
    this.totalRecords = this.factureService.total
    if (this.date && this.date[0]) {
      const startDate = this.date[0];
    const oneDayBefore = new Date(startDate);
    oneDayBefore.setDate(startDate.getDate() - 1);
    this.date[0] = oneDayBefore
    console.log('One day before:', oneDayBefore);
    console.log('output datevente:', this.date);
  }
  if (this.date && this.date[1]) {
    const startDate = this.date[1];
  const oneDayBefore = new Date(startDate);
  oneDayBefore.setDate(startDate.getDate() - 1);
  this.date[1] = oneDayBefore
  console.log('One day before:', oneDayBefore);
  console.log('output datevente:', this.date);
}
this.TotalNet = this.factureitems.reduce((acc, item) => acc + Number(item.netpaye), 0);
 this.loading = false ;
}catch (error) {
  console.error('Error fetching data:', error);
  throw error; 
}

this.pageLinkSize = Math.round(this.totalRecords / this.size);
}




// -------- filter ------------------//


async resetFilters() {
  
//   this.selectedEtablissement = [];
//   this.selectedStore = [];
//   this.selectedClient = [];
//   this.selectedArticle = [];
//   this.datevente = '';
//   await this.GenerVente.getnumvente().then((data) => {
//     this.totalRecords = data;
//  });
//   this.getVentes('', '', this.page, this.size);
  }

  async Filters() {

    this.loading = true;
 
    await this.getfacture()
    this.loading = false;

  }
  
  sendfacture(data) {
  let navigationExtras: NavigationExtras = {
    state: {
      facture: data.numero_facture
    }
  };
  console.log(' les donness sont : ',data.numero_facture) 
  this.router.navigate(['/pages/consultation-ticket'], navigationExtras);
}

   //--------------Get filters data ------------------//

   getClients(){
    this.venteService.getClients().then(res=>{
      // console.log('client',res)
    this.Clients=res.data.map(item=>({id:item.id,name:item.attributes.name}))
    console.log('clients',this.Clients)
  })
  }
  
   filterEtabFact(event: AutoCompleteCompleteEvent) {
      let filtered: any[] = [];
      let query = event.query;

      for (let i = 0; i < (this.EtabFact as any[])?.length; i++) {
          let country = (this.EtabFact as any[])[i];
          if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
              filtered.push(country);
          }
      }

      this.filtredEtabFact = filtered;
    }
  
  async getArticles(){
    await this.articleService.getArticles().then(res=>
      {console.log(res)
      this.articles=res?.data.map(items=>
        ({
          id: items.id,
          code_article_generique:items?.attributes?.code_article_generique,
          code_fournisseur:items?.attributes?.code_fournisseur,
          designation:items?.attributes?.designation,
          sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
          collection:items?.attributes?.collection.data?.attributes?.name,
          rayon:items?.attributes?.rayon.data?.attributes?.name,
          prix_achat_ttc:items?.attributes?.prix_achat_ttc,
          prix_vente_ttc:items?.attributes?.prix_vente_ttc,
          prix_achat_ht:items?.attributes?.prix_achat_ht,
          prix_vente_ht:items?.attributes?.prix_vente_ht,
          fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
          nature:'',
          Unite_de_stock:'',
          variations:items?.attributes?.variations.data
        })
        )
      
      })
      console.log(this.articles)
     
  }

  async getEtablissement(){
    await this.venteService.getEtablissements().then(res=>{
       
     console.log(res)
     this.Etablissements=res.data.map(item=>({id:item.id,name:item.attributes.name,stores:item.attributes.stores.data.map(
       store=>({id:store.id,name:store.attributes.name})
     )}))})
     console.log(this.Etablissements)
 
  }


    //------------filtres--------------------//


event: AutoCompleteCompleteEvent

filterClient(event: AutoCompleteCompleteEvent) {
  let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.Clients as any[]).length; i++) {
      let country = (this.Clients as any[])[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filtredClient = filtered;
}

filterArticle(event: AutoCompleteCompleteEvent) {
let filtered: any[] = [];
let query = event.query;

for (let i = 0; i < (this.articles as any[]).length; i++) {
    let country = (this.articles as any[])[i];
    if (country.code_article_generique.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
    }
}

this.filteredArticle = filtered;
}

filterStore(event: AutoCompleteCompleteEvent) {
let filtered: any[] = [];
let query = event.query;

for (let i = 0; i < (this.Depots as any[])?.length; i++) {
    let country = (this.Depots as any[])[i];
    if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
    }
}

this.filtredStore = filtered;
}

filterEtablissement(event: AutoCompleteCompleteEvent) {
let filtered: any[] = [];
let query = event.query;

for (let i = 0; i < (this.Etablissements as any[]).length; i++) {
    let country = (this.Etablissements as any[])[i];
    if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
    }
}

this.filtredEtablissement = filtered;
console.log('qdqsd',this.filtredEtablissement)
}

 getSelectedEtabFact() {
         console.log('store') 
      }

//--------------- PAginator , loading and sort -----------------//

async showfacture(index){
  const data =  await this.factureService.getFactureID(index)
  console.log(data)

  await this.factureDialog.showDialog(true, '', '', '',true ,data);
}

async printfacture(index){
  const data =  await this.factureService.getFactureID(index)
  console.log(data)

  await this.factureDialog.showDialog(true, '', '', '',true ,data , true);
  this.factureDialog.generateInvoicePDF();
}



onPageChangePaginator(event: any) {
  console.log(event)
  this.page = event.page + 1; 
  this.size = event.rows;
  console.log(this.page)
  console.log(this.size)
  this.getfacture();
}
get totalPages(): number {
  return Math.ceil(this.totalRecords / this.size);
}

isPageMinimized() {
  return this.pageMinimized;
}
onLazyLoad(event: LazyLoadEvent): void {
  console.log('Sort Event:', event);
this.sortField = event.sortField;
this.sortOrder = event.sortOrder;
console.log('Sort Field = ', this.sortField, ' Sort Order = ', this.sortOrder);
this.getfacture()
}

onSortChange(event: any): void {
  // Handle sorting changes
  this.sortField = event.field;
  this.sortOrder = event.order;
  console.log('Sort Fiedzdld = ', this.sortField, ' Sort Order = ', this.sortOrder);
  // Call your API with sortField and sortOrder parameters
}

}


