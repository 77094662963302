export class TicketZ {
  id: number;
  attributes: TicketAttributes;
  selected : boolean = false ;

  constructor(id: number, attributes: TicketAttributes) {
    this.id = id;
      this.attributes = attributes;
  }
}

export class TicketAttributes {
  id: number;
  date_Debut: string;
  date_fin: string;
  numero: number;
  operationCH: string;
  operationCHQte : number;
  store: StoreData;
  nbTicket: number;
  modReglement: ModReglement[];

  constructor(date_Debut: string,date_fin: string, numero: number,store: StoreData,operationCH: string ,operationCHQte: number, nbTicket: number, modReglement: ModReglement[]) {
      this.date_Debut = date_Debut;
      this.date_fin = date_fin;
      this.numero = numero;
      this.store = store;
      this.operationCH = operationCH;
      this.operationCHQte  = operationCHQte;
      this.nbTicket = nbTicket;
      this.modReglement = modReglement;
  }
}



export class StoreData {
  data: StoreAttributes;

  constructor(data: StoreAttributes) {
      this.data = data;
  }
}

export class StoreAttributes {
  id: number;
  name: string;
  code: string;
  createdAt: string;
  updatedAt: string;
  code_facture: string;

  constructor(id: number, name: string, code: string, createdAt: string, updatedAt: string, code_facture: string) {
      this.id = id;
      this.name = name;
      this.code = code;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.code_facture = code_facture;
  }
}

export class ModReglement {
  code: string;
  name: string;
  qty: number;
  ch: number;

  constructor(code: string, name: string, qty: number, ch: number) {
      this.code = code;
      this.name = name;
      this.qty = qty;
      this.ch = ch;
  }
}