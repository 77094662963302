<div class="grid">
	<div class="col-12">
		<div class="card">

			<!--------------------Consultation Tickets PAGE---------------------->

            <h5>Export</h5>
			<div class="container" >
                <div *ngIf="isLoading" class="overlay">
                    <mat-spinner></mat-spinner>
                </div>
                <div style="display: flex; margin: 20px; justify-content: flex-end;">
                    <p-button (click)="showDialog()" icon="pi pi-external-link" label="Actions"></p-button>
                    </div>

                <p-dialog header="Actions" [(visible)]="visible"  [style]="{ width: '20vw' }"  >
                        <p-listbox *ngIf="!loading" class="flex py-2 justify-content-center" [options]="filteredItems" [(ngModel)]="selectedItem"  [style]="{'width':'15rem'}" [multiple]="false" [metaKeySelection]="false" [listStyle]="{'max-height': '220px'}"></p-listbox>
                    <div *ngIf="!loading" class="flex py-2 justify-content-center">
                            <label for="file-upload"  class="custom-file-upload">
                                <i class="pi pi-download"></i>
                                Importer
                            </label>
                            <input icon="pi pi-external-link" id="file-upload" type="file" style="display:none;" (change)="onFileChange($event)" /> 
                            <p-button *ngIf="visibleitem()" (click)="getArticle()" icon="pi pi-upload" label="Exporter"></p-button>		
                    </div>
                    <p-progressSpinner *ngIf="loading" ariaLabel="loading"></p-progressSpinner>
                </p-dialog>

                <div style="padding: 10px;"></div>

                <p-pickList [source]="attributeList" [target]="selectedProducts" sourceHeader="Disponibles" targetHeader="Selectionnés" [dragdrop]="true"
                [responsive]="true" [sourceStyle]="{'height':'30rem'}" [targetStyle]="{'height':'30rem'}"
                sourceFilterPlaceholder="Search by this" targetFilterPlaceholder="Search by this" (onMoveToTarget)="onMoveToTarget($event)">
                <ng-template let-product pTemplate="item">
                    <div class="product-item">
                        <div class="product-list-detail">
                            <h5 class="mb-2">{{product}}</h5>
                        </div>
                    
                    </div>
                </ng-template>
                </p-pickList>
            </div>

	  <!------------------------------------------------------------------------------------------------------------------------>
      <div style="display: flex; margin-top: 20px; justify-content: flex-end;">
        <p-toast></p-toast>
        <p-button *ngIf="selectedProducts.length>0" (click)="senddata()"  icon="pi pi-send" label="Envoyer"></p-button>		
        </div>

   
		<div class="card flex justify-content-center"></div>
        <!------------------------------------------------------------------------------------------------------------------------>

        
<p-dialog header="Header" [(visible)]="visibleExp" [modal]="true" closeIcon="false" >
    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">Export </span>
        </div>
    </ng-template>
    <p-listbox [options]="keys" [(ngModel)]="selecteddata"  [style]="{'width':'15rem'}" [multiple]="true" [metaKeySelection]="false" [listStyle]="{'max-height': '220px'}"></p-listbox>
    <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="Checkdata()" *ngIf="visibility()" label="Ok" pAutoFocus [autofocus]="true" ></p-button>
    </ng-template>
</p-dialog>


</div></div>





