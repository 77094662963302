<!-- details-ticket.component.html -->
<p-dialog header="Facture Details" [(visible)]="visible"  [modal]="true" [style]="{ width: '70%' }">
  <!-- <div>{{ selectedVente.numeroTicket }}</div> -->
      <p-fieldset legend="Détails ticket" [toggleable]="true">

      <div *ngIf="selectedVente" class="details">
    
        <div class="details-container">
          <p-autoComplete 
          *ngIf="modifier" 
          [(ngModel)]="ClientInfo"
          [suggestions]="filtredClient" 
          (completeMethod)="filterClient($event)"
          placeholder="Client" 
          field="name"
          [dropdown]="true"
          style = "margin: 5px;" 
          (onSelect)="getSelectedClient()"
      ></p-autoComplete>
          <!---------------------------------------->
          <div class="container">
           
            <div class="title"><strong>Nom:</strong></div>
            <div>{{ ClientInfo?.name }}</div>
            <div class="title"><strong>Code Client :</strong></div>
            <div>{{ ClientInfo?.clientcode }}</div>
            <div class="title"><strong>Adresse:</strong></div>
            <div>{{ ClientInfo?.address }}</div>
            <div class="title"><strong>Numéro :</strong></div>
            <div>{{ ClientInfo?.phone }}</div>
          </div>
        </div>

        
        <!---------------------------------------->

        <div class="details-container">
          <!-- Container 1 -->
          <div class="container">
            <div class="title"><strong>Etablissement:</strong></div>
            <div>{{ selectedVente.etablissement }}</div>
            <div class="title"><strong>Store:</strong></div>
            <div>{{ selectedVente.store }}</div>
            <div class="title"><strong>Date Vente:</strong></div>
            <div *ngIf="!modifier">{{ selectedVente.date_vente | date:'dd - MM - yyyy' }}</div>
            <p-calendar  *ngIf="modifier" [touchUI]="true" [(ngModel)]="selectedVente.date_vente"
                 selectionMode="single"
                 placeholder="Date"
                 [readonlyInput]="true"
                 (ngModelChange)="onDateChange($event)"
                 ></p-calendar>
          </div>
        </div>
        
        <!-- <div class="details-container">
          <div class="container">
            <div  class="title"><strong>Pourcentage Remise:</strong></div>
            <input  *ngIf="modifier" [(ngModel)]="remisePercentage" type="number" style="width: 50px; color: blue; border-style: none;" 
            (ngModelChange)="updateValues()" (input)="validateInput()" min="0" max="100" style = "margin-bottom: 20px;">
            <div *ngIf="!modifier">{{ selectedVente.pourcentage_remise }}</div>
        
            <div class="title"><strong>Remise:</strong></div>
            <div>{{ remiseAmount !== null ? (remiseAmount | roundToThreeDecimals) : '' }}
              <span style="margin-right: 5px;">TND</span>
            </div>
          </div>
        </div> -->
        
        <div class="details-container">
          <!-- Container 3 -->
          <div class="container">
            <div class="title"><strong>HT:</strong></div>
            <div>{{ htAmount !== null ? (htAmount | roundToThreeDecimals) : '' }}
              <span style="margin-right: 5px;">TND</span>
            </div>
            <div class="title"><strong>Taxes:</strong></div>
            <div>{{ taxesAmount !== null ? (taxesAmount | roundToThreeDecimals) : '' }}
              <span style="margin-right: 5px;">TND</span>
            </div>
            <div class="title"><strong>TTC:</strong></div>
            <div>{{ ttcAmount !== null ? (ttcAmount | roundToThreeDecimals) : '' }}
              <span style="margin-right: 5px;">TND</span>
            </div>
          </div>
        </div>
        <div class="details-container">
          <!-- Container 4 -->
          <div class="container">
            <div *ngIf="showFodec"  class="title"><strong>Total Fodec:</strong></div>
            <div *ngIf="showFodec"  >{{ totalFodec !== null ? (totalFodec | roundToThreeDecimals) : '' }}
              <span style="margin-right: 5px;">TND</span>
            </div>
        
            <div class="title" *ngIf="timbre>=0"  ><strong>Timbre:</strong></div>
            <div  *ngIf="timbre>=0" >{{ timbre !== null ? (timbre | roundToThreeDecimals) : '' }}
              <span style="margin-right: 5px;">TND</span>
            </div>
        
            <div class="title"><strong>Prix Net:</strong></div>
            <div>{{ netToPayAmount !== null ? (netToPayAmount | roundToThreeDecimals) : '' }}
              <span style="margin-right: 5px;">TND</span>
            </div>
          </div>
        </div>
        
        
      </div>
      
      </p-fieldset>


  <div *ngIf="selectedVente" class="detailstab">
<div class="flex" *ngIf="modifier" >
  <p-button   (click)="openDialog()" icon="bi bi-plus" label="Ajouter un produit "></p-button>
</div>
<p-table
  [value]="GroupeVariations"
  responsiveLayout="stack"
  scrollHeight="flex"
  [paginator]="true"
  [rows]="5"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
  dataKey="code_article_generique"
  [showCurrentPageReport]="true"
  [loading]="isloading"
>
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>code_article_generique</th>
      <th>Quantité</th>
      <th>remise</th>
      <th>Prix U.HT</th>
      <th>montant</th>
      <th *ngIf="modifier">Action</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-group let-ri="rowIndex" >
    <tr>
      <td>
        <button
        *ngIf="dimension"
        type="button"
        pButton
        pRipple
        [pRowToggler]="group"
        class="p-button-text p-button-rounded p-button-plain"
        [icon]="group.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        (click)="toggleRow(group, ri)"
      ></button>
      </td>
      <td>{{ group.code_article_generique }}</td>
      <td >
        <!-- Display input field if modifier is true, otherwise display quantity -->
        <ng-container *ngIf="modifier; else displayQuantity">
                                        <p-inputNumber [showButtons]="true"
     
           [(ngModel)]="group.quantite"  [min]="1" (onInput)="onQuantityChange($event.value , rowIndex , ri)"
                                        ></p-inputNumber>
          <!--  mode="decimal"-->
          <!--  [showButtons]="true"-->
          <!--  inputId="quantity-input"-->
          <!-- [min]="0"-->
          <!-- [step]="1"-->
          <!-- (ngModelChange)="onQuantityChange($event, ri)"-->
          <!--></p-inputNumber>-->
        </ng-container>
        <ng-template #displayQuantity>{{ group.quantite }}</ng-template>
      </td>
      <td> <ng-container *ngIf="modifier; else displayRemise">
        <p-inputNumber
          [(ngModel)]="group.remise"
          mode="decimal"
          [showButtons]="true"
          inputId="remise-input"
          [min]="0"
          [max]="100"
          (ngModelChange)="onRemiseChange($event, rowIndex, ri)"
        ></p-inputNumber>
      </ng-container> </td>
      <ng-template #displayRemise>{{ group.remise | roundToThreeDecimals }} %</ng-template>
      <td>{{ group.variations[0].ht | currency: 'TND': 'symbol':'1.3-3' }}</td>
      <td>{{ group.montant  | roundToThreeDecimals }}</td>
      <td *ngIf="modifier"><p-button icon="pi pi-times"  severity="danger" (click)="deletealldata(ri)"></p-button></td>
    </tr>
   
  </ng-template>

  
  <ng-template *ngIf="dimension" pTemplate="rowexpansion" let-group let-ri="rowIndex" > 
    <tr >
      <td colspan="5">
        <!-- <div class="p-p-6"> -->
            <div class="p-p-6" style="text-align: center; color: rgb(159, 195, 216);">
                <p-table  [loading]="isloading" [value]="group.variations" dataKey="id">
            <ng-template pTemplate="header">
              <tr style="text-align: center; background-color: rgb(159, 195, 216);" >
                <th style="text-align: center; background-color: rgb(159, 195, 216);">code_article_generique</th>
                <th style="text-align: center; background-color: rgb(159, 195, 216);">quantité</th>
                <th  style="text-align: center; background-color: rgb(159, 195, 216);">remise</th>
                <th style="text-align: center; background-color: rgb(159, 195, 216);">prix_ht</th>
                <th style="text-align: center; background-color: rgb(159, 195, 216);">tva</th>
                <th style="text-align: center; background-color: rgb(159, 195, 216);">prix_Ttc</th>
                <th *ngIf="modifier" style="text-align: center; background-color: rgb(159, 195, 216);">Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vente let-rowIndex="rowIndex">
              <tr>
                <td>{{ vente.code_article_generique }}</td>
                <td>
                  <!-- Display input field if modifier is true, otherwise display quantity -->
                  <ng-container *ngIf="modifier; else displayQuantity">
                    <p-inputNumber
                      [(ngModel)]="vente.quantity"
                      mode="decimal"
                      [showButtons]="true"
                      inputId="quantity-input"
                      [min]="0"
                      (ngModelChange)="onQuantityChange($event, rowIndex , ri)"
                    ></p-inputNumber>
                  </ng-container>
                  <ng-template #displayQuantity>{{ vente.quantity }}</ng-template>
                </td>
                <td>
                  <!-- Display input field if modifier is true, otherwise display remise -->
                  <ng-container *ngIf="modifier; else displayRemise">
                    <p-inputNumber
                      [(ngModel)]="vente.remise"
                      mode="decimal"
                      [showButtons]="true"
                      inputId="remise-input"
                      [min]="0"
                      [max]="100"
                      (ngModelChange)="onRemiseChange($event, rowIndex, ri)"
                    ></p-inputNumber>
                  </ng-container>
                  <ng-template #displayRemise>{{ vente.remise }}</ng-template>
                </td>
                <td>{{ vente.ht | currency: 'TND': 'symbol':'1.3-3' }}</td>
                <td>{{ vente.tva }}</td>
                <td>
                  {{ vente.prix_produit !== null ? (vente.prix_produit | currency: ' ': 'symbol':'1.3-3') : '' }}
                  <span style="margin-right: 5px;">TND</span>
                </td>
                <td *ngIf="modifier" ><p-button icon="pi pi-times"  severity="danger" (click)="deletedata(rowIndex , ri)"></p-button></td>
              </tr>
            </ng-template>

          </p-table>
        </div>
                        <!-- </div> -->
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft"></ng-template>
  <ng-template pTemplate="paginatorright"></ng-template>
</p-table>



  
<app-add-vente-dialog (dataSent)="receiveTicket($event)"></app-add-vente-dialog>
<footer class="footer-container">
  <p-button style="margin-right: 10px;" *ngIf="modifier" (click)="updateTicket()" icon="bi bi-check" label="Modifier"></p-button>
  <p-button *ngIf="modifier" (click)="visible = false" icon="bi bi-check" label="Annuler"></p-button>
</footer>
</div>

</p-dialog>
